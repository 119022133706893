import { request } from "../request";

/** msgSecCheck POST /wx/mini/msgSecCheck */
// 获取用户数据列表
export async function getUserList(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/org/user/list", {
    method: "POST",
    // headers: {
    //   'Content-Type': 'application/json',
    // },
    data: body,
    ...(options || {}),
  });
}

export async function getUserListNoAuth(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/org/user/list-no-auth", {
    method: "POST",
    // headers: {
    //   'Content-Type': 'application/json',
    // },
    data: body,
    ...(options || {}),
  });
}

// 编辑用户基础信息
export async function saveUser(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/user/save", {
    method: "POST",
    // headers: {
    //   'Content-Type': 'application/json',
    // },
    data: body,
    ...(options || {}),
  });
}

// 新增用户角色等数据
export async function addUserRole(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/org/user/add", {
    method: "POST",
    // headers: {
    //   'Content-Type': 'application/json',
    // },
    data: body,
    ...(options || {}),
  });
}

// 编辑用户角色等数据
export async function saveUserRole(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/org/user/confRole", {
    method: "POST",
    // headers: {
    //   'Content-Type': 'application/json',
    // },
    data: body,
    ...(options || {}),
  });
}

// 获取用户角色
export async function getUserRole(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/org/role/admin", {
    method: "POST",
    // headers: {
    //   'Content-Type': 'application/json',
    // },
    data: body,
    ...(options || {}),
  });
}

// 删除用户数据
export async function delUserList(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/org/user/del", {
    method: "POST",
    // headers: {
    //   'Content-Type': 'application/json',
    // },
    data: body,
    ...(options || {}),
  });
}

// 新增用户列表
export async function addUserList(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/org/user/add", {
    method: "POST",
    // headers: {
    //   'Content-Type': 'application/json',
    // },
    data: body,
    ...(options || {}),
  });
}

// 搜索用户列表
export async function searchUserList(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/user/list", {
    method: "POST",
    // headers: {
    //   'Content-Type': 'application/json',
    // },
    data: body,
    ...(options || {}),
  });
}

// 获取用户自己所拥有的系统权限
export async function getSystemPurview(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/user/client/user/client", {
    method: "POST",
    // headers: {
    //   'Content-Type': 'application/json',
    // },
    data: body,
    ...(options || {}),
  });
}

// 冻结-解冻用户
export async function modifyStatus(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/org/user/freeze", {
    method: "POST",
    // headers: {
    //   'Content-Type': 'application/json',
    // },
    data: body,
    ...(options || {}),
  });
}

// 修改机构管理员
export async function modifyOrgAdmin(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/org/user/modify/admin", {
    method: "POST",
    // headers: {
    //   'Content-Type': 'application/json',
    // },
    data: body,
    ...(options || {}),
  });
}

// 修改机构管理员
export async function resetPasswordByAdmin(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/user/login/reset/password", {
    method: "POST",
    // headers: {
    //   'Content-Type': 'application/json',
    // },
    data: body,
    ...(options || {}),
  });
}
