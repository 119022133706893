import dateJS from "@/utils/date";
import { Button, Card, Col, Form, Input, Row, Space, Table, Tag } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import imgUrl from "../../assets/images/home_bg.svg";
import defaultAvatar from "../../assets/logo.png";
import bannerIcon from "../../assets/logo.png";
import articleIcon from "../../assets/logo.png";
import agencyIcon from "../../assets/logo.png";
import settingIcon from "../../assets/logo.png";
import styles from "./index.module.css";
import { DeliveredProcedureOutlined, UnlockOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import SERVICE_API from "@/api";

export default function Hone() {
  //   设置分页
  const [pageParam, setPageParam] = useState({
    pageSize: 10,
    current: 1,
  });
  // 获取列表数据
  const getAppUserList = async (params: any) => {
    const res = await SERVICE_API.logs.getOpLogList({
      ...params,
    });
    setPageParam({ ...params });
    return {
      success: res?.code === 0, //   刷新，如果res.code===0 就不刷新了
      data: res?.data?.list, //    表格的数据
      total: res?.data?.total,
    };
  };

  useEffect(() => {
    getAppUserList(pageParam);
  }, [pageParam]);

  const columns: ColumnsType<any> = [
    {
      title: "编号",
      dataIndex: "recordId",
      key: "recordId",
      align: "center",
      render: (text) => <a style={{ fontSize: 12 }}>{text}</a>,
    },
    {
      title: "操作",
      dataIndex: "operation",
      key: "operation",
      align: "center",
      render: (text) => <span style={{ fontSize: 12 }}>{text}</span>,
    },
    {
      title: "操作内容",
      dataIndex: "commit",
      key: "commit",
      align: "center",
      render: (text) => <a style={{ fontSize: 12 }}>查看</a>,
    },
    {
      title: "IP地址",
      dataIndex: "ip",
      key: "ip",
      align: "center",
      render: (text) => <span style={{ fontSize: 12 }}>{text}</span>,
    },
    {
      title: "时间",
      key: "createDt",
      dataIndex: "createDt",
      align: "center",
      render: (text) => <span style={{ fontSize: 12 }}>{text}</span>,
    },
  ];

  const data: any[] = [
    {
      recordId: 1,
      operation: "登录",
      commit: "登录成功",
      ip: "192.168.0.1",
      createDt: "2021-08-01 12:00:00",
    },
    {
      recordId: 2,
      operation: "导出文件",
      commit: "导出文件",
      ip: "192.168.0.2",
      createDt: "2021-08-01 12:00:00",
    },
    {
      recordId: 3,
      operation: "修改管理员信息",
      commit: "登录成功",
      ip: "192.168.0.3",
      createDt: "2021-08-01 12:00:00",
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      <Card style={{ marginBottom: 24 }} bodyStyle={{ padding: 15 }}>
        <Form size="middle">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="操作" name="operation">
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="IP地址" name="ip">
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="时间" name="time">
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                <Space>
                  <Button type="primary">查询</Button>
                  <Button type="primary" ghost>
                    导出
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
      <Table
        columns={columns}
        dataSource={data}
        size="small"
        rowKey={(record) => record.recordId}
      />
    </div>
  );
}
