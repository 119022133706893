import {
  HomeOutlined,
  DeliveredProcedureOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import styles from "./index.module.css";
import { Dropdown, MenuProps, Modal, Row, Space, message } from "antd";
import logo from "../assets/logo.png";
import { useEffect, useRef, useState } from "react";
import SERVICE_API from "@/api";
import history from "@/utils/history";
import projectConfig from "@/config/projectConfig";

const Layout = ({ children }: any) => {
  const boxRef = useRef<any>(null);
  const [path, setPath] = useState<string>("/");
  const location = useLocation();
  const currentPath = location.pathname;
  const needLayout = () => {
    const unneedLayout = ["/user/login"];
    return !unneedLayout.includes(location.pathname);
  };
  const menus = [
    {
      name: "工作台",
      path: "/",
      icon: <HomeOutlined />,
    },
    {
      name: "安全导出",
      path: "/security-export",
      icon: <DeliveredProcedureOutlined />,
    },
    {
      name: "安全导入",
      path: "/security-import",
      icon: <UnlockOutlined />,
    },
  ];

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <a
          // to="/admin-home"
          //   target="new_blank"
          style={{ color: "#666", fontSize: 13 }}
          onClick={async () => {
            const res = await SERVICE_API.login.getUserInfo({});
            if (res.code != 0) return;
            history.push("/admin-home");
          }}
        >
          管理后台
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <Link to="/setting" style={{ color: "#666", fontSize: 13 }}>
          系统设置
        </Link>
      ),
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      const box = boxRef.current;
      message.config({
        top: box.getBoundingClientRect().top + 6,
      });
    }, 3000);
  }, []);
  if (!needLayout()) {
    return (
      <main className={styles.layout}>
        <div
          className={styles.box}
          ref={boxRef}
          style={{ borderRadius: window.electron ? "0" : "10px" }}
        >
          {children}
        </div>
      </main>
    );
  }
  return (
    <main className={styles.layout}>
      <div
        className={styles.box}
        ref={boxRef}
        style={{ borderRadius: window.electron ? "0" : "10px" }}
      >
        <div className={styles.sider}>
          <div className={styles.title_box}>
            <img className={styles.logo} src={logo} alt="logo" />
            <div className={styles.name}>数据受控工具</div>
          </div>
          <div className={styles.menu}>
            {menus.map((item, index) => {
              return (
                <Link
                  key={index}
                  to={item.path}
                  style={{ color: "inherit", fontSize: "inherit" }}
                  onDragStart={(e) => e.preventDefault()}
                >
                  <div
                    className={`${styles.item} ${
                      currentPath === item.path && styles.item_active
                    }`}
                  >
                    <Space className={styles.link}>
                      {item.icon}
                      {item.name}
                    </Space>
                  </div>
                </Link>
              );
            })}
          </div>
          <div className={styles.footer}>
            <Row justify="space-between" align="middle">
              <span style={{ fontSize: 13, color: "#ffffff" }}>
                版本：V{projectConfig.version}
              </span>
              <Dropdown
                menu={{ items }}
                placement="top"
                arrow
                trigger={["hover"]}
              >
                <span style={{ color: "#fff", cursor: "pointer" }}>设置</span>
              </Dropdown>
            </Row>
            {/* <div
              style={{
                fontSize: 12,
                textAlign: "center",
                color: "#fff",
                borderTop: "1px solid #eeeeee33",
                marginTop: 12,
                padding: "6px 0",
              }}
            >
              版本：V1.0.0
            </div> */}
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.header}></div>
          <div className={styles.main}>{children}</div>
        </div>
      </div>
    </main>
  );
};

export default Layout;
