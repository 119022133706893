/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request ---RequestOptionsInit
 */
/* eslint-disable */
import { notification, Modal, message } from "antd";
import Cookies from "js-cookie";
import projectConfig from "@/config/projectConfig";
import history from "../utils/history";
// somewhere like a redux/flux action file:

const codeMessage = {
  200: "服务器成功返回请求的数据。",
  201: "新建或修改数据成功。",
  202: "一个请求已经进入后台排队（异步任务）。",
  204: "删除数据成功。",
  400: "发出的请求有错误，服务器没有进行新建或修改数据的操作。",
  401: "用户没有权限（令牌、用户名、密码错误）。",
  403: "用户得到授权，但是访问是被禁止的。",
  404: "发出的请求针对的是不存在的记录，服务器没有进行操作。",
  406: "请求的格式不可得。",
  410: "请求的资源被永久删除，且不会再得到的。",
  422: "当创建一个对象时，发生一个验证错误。",
  500: "服务器发生错误，请检查服务器。",
  502: "网关错误。",
  503: "服务不可用，服务器暂时过载或维护。",
  504: "网关超时。",
};

/**
 * 异常处理程序;
 */
export const errorHandler = (error: any) => {
  const { response } = error;
  if (response && response.status) {
    const errorText = codeMessage[response.status] || response.statusText;
    const { status, url } = response;
    notification.error({
      message: `请求错误 ${status}: ${url}`,
      description: errorText,
    });
    // throw new Error(`请求错误 ${status}\n 请求URL为${url}\n 错误提示：${errorText}`);
  } else if (!response) {
    notification.error({
      description: "请求错误，请重试",
      message: "网络异常",
    });
  }
  return response;
};

// request拦截器；
// eslint-disable
export const requestInterceptor = (url: string, options: any) => {
  const userLocal =
    localStorage.getItem("user") || sessionStorage.getItem("user");
  const tokenStr: any = localStorage.getItem("adminlogin@token");
  const tokenObj: any =
    tokenStr && tokenStr !== "null" && tokenStr !== "undefined"
      ? JSON.parse(tokenStr)
      : {};
  const orgInfoStr: string | null = localStorage.getItem("orgInfo");
  const orgInfo: any =
    orgInfoStr && orgInfoStr !== "null" && orgInfoStr !== "undefined"
      ? JSON.parse(orgInfoStr)
      : {};

  const token = tokenObj.value;
  const client = projectConfig.client;
  const orgId = orgInfo?.orgId;
  if (url.indexOf("http") === -1) {
    // if (client) url = url + '?client=' + client;
    // if (orgId) {
    //   if (url.indexOf('?') === -1) url = url + '?org=' + orgId;
    //   else url = url + '&org=' + orgId;
    // }
    // 全局添加前缀
    url = projectConfig.baseUrl + projectConfig.apiPrefix + url;
  }
  // get 请求自动参数拼接
  if (options.method == "get") {
    options = {
      ...options,
      params: options.data || options,
    };
  }
  let newOptions = {
    ...options,
  };
  if (userLocal) {
    let user = JSON.parse(userLocal);
    newOptions = {
      ...options,
      data: {
        ...options.data,
        createUserId: user.userId,
        companyId: user.scenicId,
      },
    };
  }
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*", // 设置跨域请求头；
  };
  if (token) headers["QC-Token"] = token;
  if (client) headers["Token-Client"] = client;
  if (orgId) headers["Token-Org"] = orgId;

  return {
    url,
    options: { ...newOptions, headers },
  };
};

// response拦截器；
export const responseInterceptor = (response: any) => {
  const data = response;
  if (data.code === 403) {
    Modal.confirm({
      title: "抱歉，您没有权限",
      content: data.msg,
      centered: true,
      cancelText: "取消",
      okText: "确定",
      // onOk: () => {
      //   localStorage.removeItem('user');
      //   sessionStorage.removeItem('user');
      //   history.push('/user/login');
      //   Modal.destroyAll();
      // },
      // onCancel: () => {
      //   Modal.destroyAll();
      // },
    });
    // if (confirm(data.msg)) {
    //   localStorage.removeItem('user');
    //   sessionStorage.removeItem('user');
    //   history.push('/user/login');
    // }
    return response;
  }
  if (data.code === 401) {
    if (data.msg.indexOf("Token无效") > -1) {
      data.msg = "当前登录已失效，请重新登录";
    }
    // message.warning(data.msg);
    // localStorage.removeItem("userInfo");
    // sessionStorage.removeItem("userInfo");
    // localStorage.removeItem("adminlogin@token");
    // sessionStorage.removeItem("adminlogin@token");
    // localStorage.removeItem("orgInfo");
    // sessionStorage.removeItem("orgInfo");
    // // 删除cookie
    // Cookies.remove("QC-Token");
    // history.push("/user/login?redirect=" + history.location.pathname);

    Modal.confirm({
      title: "未登录或登录失效",
      content: "访问该功能需要登录，请确认是否已登录",
      centered: true,
      cancelText: "取消",
      okText: "确定",
      onOk: () => {
        localStorage.removeItem("userInfo");
        sessionStorage.removeItem("userInfo");
        localStorage.removeItem("adminlogin@token");
        sessionStorage.removeItem("adminlogin@token");
        localStorage.removeItem("orgInfo");
        sessionStorage.removeItem("orgInfo");
        // 删除cookie
        Cookies.remove("QC-Token");
        history.push("/user/login?redirect=" + history.location.pathname);
        // window.location.reload();
        Modal.destroyAll();
      },
      onCancel: () => {
        Modal.destroyAll();
        history.push("/");
      },
    });
    return response;
  }
  if (data.code === 500) {
    message.error("服务器错误" + (data.msg?.length < 30 ? `:${data.msg}` : ""));
    return response;
  }
  if (data.code != 0) {
    message.error(data.msg?.length < 50 ? data.msg : "请求发生错误");
    return response;
  }
  if (response.status === 500) {
    // eslint-disable-next-line consistent-return 避免出现500而报错
    const tmpArr = response.url.split("/");
    const tmpStr = `${tmpArr[tmpArr.length - 2]}/${tmpArr[tmpArr.length - 1]}`;
    const newresponse = { code: 0, msg: "500服务端错误" };
    notification.error({
      description: `URL：${tmpStr}`,
      message: "服务端错误，请刷新重试",
    });
    return newresponse;
  }
  return response;
};
