import { Alert, Button, Form, Modal, Row, Tabs, message } from "antd";
import ProForm, {
  ProFormCaptcha,
  ProFormCheckbox,
  ProFormText,
} from "@ant-design/pro-form";
import React, { useEffect, useState } from "react";
// import Footer from '@/components/Footer';     //  引入登录底部版权

import SERVICE_API from "@/api";
import hex_md5 from "@/utils/md5";
import { genUuid, parseQueryString } from "@/utils/utils";
import styles from "./index.module.css";
import { useNavigate } from "react-router-dom";
import history from "@/utils/history";

const LoginMessage: React.FC<{
  content: string;
}> = ({ content }) => (
  <Alert
    style={{
      marginBottom: 24,
    }}
    message={content}
    type="error"
    showIcon
  />
);

type PropsType = {
  onCheck?: () => void;
};

const Login: React.FC<PropsType> = (props: PropsType) => {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [userLoginState, setUserLoginState] = useState<any>({});
  const [type, setType] = useState<string>("account");
  const [picCaptcha, setPicCaptcha] = useState<any>({});
  const formRef = React.useRef<any>();

  const fetchUserInfo = async () => {
    const res: any = await SERVICE_API.login.getUserInfo({});
    // 把下面的方法改造成异步的
    if (res.code === 0) {
      localStorage.setItem(
        "userInfo",
        JSON.stringify(res.data?.userInfo || {})
      ); // 保存用户详情
      const userParametersRes: any = await SERVICE_API.login.getUserParameters(
        {}
      );
      const userPar = userParametersRes?.data;
      const orgInfo = JSON.stringify(userPar?.info); //  获取角色ID
      localStorage.setItem("orgInfo", orgInfo); //  保存机构ID
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(res.data?.userInfo);
      }, 200);
    });
  };

  // 获取图形验证码
  const getPicCaptcha = async () => {
    const result: any = await SERVICE_API.captcha.getLoginPicCaptcha({
      id: picCaptcha?.id || genUuid(32, 16).toLocaleLowerCase(),
    });
    if (result.code === 0) {
      setPicCaptcha(
        result.data
          ? { ...result.data, img: `data:image/png;base64,${result.data.img}` }
          : {}
      );
    }
  };

  // 点击登录发送请求
  const handleSubmit = async (values: any) => {
    setSubmitting(true);
    try {
      // 忽略大小写
      values.verifyCodePic = values.verifyCodePic.toLowerCase();
      if (values.verifyCodePic !== picCaptcha?.code?.toLowerCase()) {
        message.error("图形验证码错误");
        setSubmitting(false);
        return;
      }
      const params = {
        ...picCaptcha,
        img: "",
        ...values,
        loginPhone: values.loginName || values.loginPhone,
        password: values.password ? hex_md5(values.password) : "",
        verifyCode: values.verifyCode,
        verifyCodeMessage: values.verifyCode,
        type,
      };

      let res: any = {};
      // 登录
      if (type === "mobile") {
        res = await SERVICE_API.login.loginByCode(params);
      } else {
        res = await SERVICE_API.login.loginBypass(params);
      }
      if (res.code === 0) {
        const str = JSON.stringify(res.data?.token);
        localStorage.setItem("adminlogin@token", str); // 保存token
        setUserLoginState(res.data.userInfo);
        const user = JSON.stringify(res.data.userInfo);
        localStorage.setItem("userInfo", user); // 保存用户详情

        message.success("登录成功");
        // 登录成功后跳转到首页
        await fetchUserInfo();
        /** 此方法会跳转到 redirect 参数所在的位置 */
        const query = parseQueryString(window.location.href);
        const { redirect } = query as unknown as { redirect: string };
        // redirect里包含了public路径，需要去掉
        // if (redirect) {
        //   navigate(redirect.replace(``, ""));
        // } else {
        //   navigate("/admin-home");
        // }
        navigate("/admin-home");
        return;
      }
      getPicCaptcha();
      // 如果失败去设置用户错误信息
      // setUserLoginState(res);
      // message.error('登录失败，' + res.msg);
    } catch (error) {
      // message.error(defaultLoginFailureMessage);
    }
    setSubmitting(false);
  };
  const { status, type: loginType } = userLoginState;

  useEffect(() => {
    getPicCaptcha();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.top}>
          <div className={styles.header}>
            {/* <Link to="/">
              <img alt="logo" className={styles.logo} src="/logo.svg" />
              <h2 className={styles.Title}>登录</h2>
            </Link> */}
          </div>
          {/* 简洁描述 */}
          {/* <div className={styles.desc}>
            {intl.formatMessage({ id: 'pages.layouts.userLayout.title' })}
          </div> */}
        </div>

        <div className={styles.main}>
          <ProForm
            initialValues={{
              autoLogin: true,
            }}
            isKeyPressSubmit
            formRef={formRef}
            submitter={{
              searchConfig: {
                submitText: "登录",
                resetText: "取消",
              },
              render: (_, dom) => dom.pop(),
              submitButtonProps: {
                loading: submitting,
                size: "large",
                style: {
                  width: "100%",
                },
              },
              resetButtonProps: {
                style: {
                  width: "100%",
                },
                size: "large",
              },
            }}
            onFinish={async (values) => {
              handleSubmit(values as any);
            }}
          >
            {/* <Tabs activeKey={type} onChange={setType} centered={true}>
              <Tabs.TabPane key="account" tab={"账号密码登录"} />
              <Tabs.TabPane key="mobile" tab={"手机号登录"} />
            </Tabs> */}

            {status === "error" && loginType === "account" && (
              <LoginMessage content={"账号密码错误"} />
            )}
            {type === "account" && (
              <>
                <ProFormText
                  name="loginName"
                  fieldProps={{
                    size: "large",
                    // prefix: (
                    //   <QcIcon className={styles.prefixIcon} type="" src="/icons/v1/icon-user.svg" />
                    // ),
                  }}
                  placeholder={"用户名/手机号"}
                  rules={[
                    {
                      required: true,
                      message: "请输入用户名",
                    },
                  ]}
                />
                <ProFormText.Password
                  name="password"
                  fieldProps={{
                    size: "large",
                    // prefix: (
                    //   <QcIcon
                    //     className={styles.prefixIcon}
                    //     type=""
                    //     src="/icons/v1/icon-password.svg"
                    //   />
                    // ),
                  }}
                  placeholder={"操作密码"}
                  rules={[
                    {
                      required: true,
                      message: "请输入密码",
                    },
                  ]}
                />
                <Row>
                  <ProFormText
                    name="verifyCodePic"
                    fieldProps={{
                      size: "large",
                      allowClear: false,
                      // prefix: (
                      //   <QcIcon
                      //     className={styles.prefixIcon}
                      //     style={{ fontSize: '20px' }}
                      //     type=""
                      //     src="/icons/v1/icon-security.svg"
                      //   />
                      // ),
                    }}
                    placeholder="请输入图形验证码"
                    rules={[
                      {
                        required: true,
                        message: "请输入图形验证码",
                      },
                      {
                        min: 4,
                        max: 4,
                        message: "请输入正确验证码",
                      },
                    ]}
                  />
                  <Row
                    style={{
                      height: "39px",
                      marginLeft: 6,
                      flex: 1,
                      background: "#f0f0f0",
                      overflow: "hidden",
                      cursor: "pointer",
                      border: "1px solid #eee",
                    }}
                    justify="center"
                    onClick={() => getPicCaptcha()}
                  >
                    <img
                      src={picCaptcha?.img}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Row>
                </Row>
              </>
            )}

            {status === "error" && loginType === "mobile" && (
              <LoginMessage content="验证码错误" />
            )}
            {type === "mobile" && (
              <>
                <ProFormText
                  fieldProps={{
                    size: "large",
                    // prefix: <MobileOutlined className={styles.prefixIcon} />,
                  }}
                  name="loginPhone"
                  placeholder={"手机号"}
                  rules={[
                    {
                      required: true,
                      message: "请输入手机号",
                    },
                    {
                      pattern: /^1\d{10}$/,
                      message: "手机号格式错误",
                    },
                  ]}
                />
                <ProFormCaptcha
                  fieldProps={{
                    size: "large",
                    // prefix: <LockOutlined className={styles.prefixIcon} />,
                  }}
                  captchaProps={{
                    size: "large",
                  }}
                  placeholder={"请输入验证码"}
                  captchaTextRender={(timing, count) => {
                    if (timing) {
                      return `${count} ${"获取验证码"}`;
                    }
                    return "获取验证码";
                  }}
                  name="verifyCode"
                  rules={[
                    {
                      required: true,
                      message: "请输入验证码",
                    },
                  ]}
                  onGetCaptcha={async () => {
                    const phone = formRef.current.getFieldValue("loginPhone");
                    const reg = /^1\d{10}$/;
                    if (!phone || !reg.test(phone)) {
                      message.error("请输入正确手机号");
                      throw new Error("请输入正确手机号");
                    }
                    const result: any =
                      await SERVICE_API.captcha.getLoginSmsCaptcha({
                        loginPhone: phone,
                      });
                    if (result?.code === 0) {
                      return;
                    }
                    message.success("验证码已发送");
                  }}
                />
                <Row>
                  <ProFormText
                    name="verifyCodePic"
                    fieldProps={{
                      size: "large",
                      allowClear: false,
                      // prefix: (
                      //   <QcIcon
                      //     className={styles.prefixIcon}
                      //     style={{ fontSize: '20px' }}
                      //     type=""
                      //     src="/icons/v1/icon-security.svg"
                      //   />
                      // ),
                    }}
                    placeholder="请输入图形验证码"
                    rules={[
                      {
                        required: true,
                        message: "请输入图形验证码",
                      },
                    ]}
                  />
                  <Row
                    style={{
                      height: "39px",
                      marginLeft: 6,
                      flex: 1,
                      background: "#f0f0f0",
                      overflow: "hidden",
                      cursor: "pointer",
                    }}
                    justify="center"
                    onClick={() => getPicCaptcha()}
                  >
                    <img
                      src={picCaptcha?.img}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Row>
                </Row>
              </>
            )}
            <div
              style={{
                marginBottom: 30,
              }}
            >
              {/* <ProFormCheckbox noStyle name="autoLogin">
                自动登录
              </ProFormCheckbox> */}
              <div style={{ float: "right" }}>
                <a
                  style={{ marginRight: 12 }}
                  onClick={() => {
                    Modal.confirm({
                      title: "忘记密码?",
                      content: (
                        <div>
                          <p>
                            忘记密码请联系管理员重置，登录成功后在【个人管理】-【账号管理】处修改密码。
                          </p>
                        </div>
                      ),
                      // onOk: () => {
                      //   setType('mobile');
                      // },
                      okText: "确定",
                      okButtonProps: {
                        ghost: true,
                      },
                      cancelButtonProps: { style: { display: "none" } },
                      centered: true,
                    });
                  }}
                >
                  忘记密码
                </a>
                {/* <a
                  // style={{ marginRight: '20px' }}
                  onClick={() => props.onCheck()}
                >
                  注册
                </a> */}
              </div>
            </div>
          </ProForm>
          <Button
            style={{ width: "100%", marginTop: 12 }}
            size="large"
            onClick={() => history.push("/")}
          >
            取消
          </Button>
          {/* 其他登录方式 */}
          {/* <Space className={styles.other}>
            <FormattedMessage id="pages.login.loginWith" defaultMessage="其他登录方式" />
            <AlipayCircleOutlined className={styles.icon} />
            <TaobaoCircleOutlined className={styles.icon} />
            <WeiboCircleOutlined className={styles.icon} />
          </Space> */}
        </div>
      </div>
      {/* 登录下面的版权组件，使用引入外部组件 */}
      {/* <Footer /> */}
    </div>
  );
};

export default Login;
