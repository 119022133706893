import { request } from "../request";

//登录用户系统
/** msgSecCheck POST /wx/mini/msgSecCheck */
export async function loginBypass(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/user/login/password", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
    ...(options || {}),
  });
}

// 获取用户信息
export async function getUserInfo(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/user/owner/detail", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
    ...(options || {}),
  });
}

// 获取用户权限
export async function getUserParameters(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/org/user/userOrg", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
    ...(options || {}),
  });
}

// 获取用户登录的信息
export async function userOrg(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/org/user/userOrg", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

// 注册新用户
export async function register(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/org/apply/save", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}
export function loginByCode(params: any): any {
  throw new Error("Function not implemented.");
}

// 更新密码
export async function changePassword(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/user/login/change/password", {
    method: "POST",
    // headers: {
    //   'Content-Type': 'application/json',
    // },
    data: body,
    ...(options || {}),
  });
}

// 退出登录
export async function logout(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/user/login/logout", {
    method: "POST",
    // headers: {
    //   'Content-Type': 'application/json',
    // },
    data: body,
    ...(options || {}),
  });
}

export function tempAuthByVerifyCode(arg0: { id: string }) {
  throw new Error("Function not implemented.");
}
export function isLogin(arg0: {}) {
  throw new Error("Function not implemented.");
}
