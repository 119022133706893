import { request } from "../request";

//操作审核
export async function auth(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/operation/audit/auth", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
    ...(options || {}),
  });
}

// 上报导出日志
export async function reportExportLog(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/operation/audit/export-log", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
    ...(options || {}),
  });
}

// 上报导入日志
export async function reportImportLog(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/operation/audit/import-log", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
    ...(options || {}),
  });
}
