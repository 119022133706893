import dateJS from "@/utils/date";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Space,
  Table,
  Tabs,
  Tag,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import imgUrl from "../../assets/images/home_bg.svg";
import defaultAvatar from "../../assets/logo.png";
import bannerIcon from "../../assets/logo.png";
import articleIcon from "../../assets/logo.png";
import agencyIcon from "../../assets/logo.png";
import settingIcon from "../../assets/logo.png";
import styles from "./index.module.css";
import {
  DeliveredProcedureOutlined,
  PlusOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import Logs from "./components/Logs";
import PersonalCenter from "./components/PersonalCenter";
import AdminUser from "./components/AdminUser";

export default function Hone() {
  const navigate = useNavigate();
  const columns: ColumnsType<any> = [
    {
      title: "用户ID",
      dataIndex: "userId",
      key: "userId",
      align: "center",
      render: (text) => <a style={{ fontSize: 12 }}>{text}</a>,
    },
    {
      title: "姓名",
      dataIndex: "realName",
      key: "realName",
      align: "center",
      render: (text) => <span style={{ fontSize: 12 }}>{text}</span>,
    },
    {
      title: "创建时间",
      dataIndex: "createDt",
      key: "createDt",
      align: "center",
      render: (text) => <span style={{ fontSize: 12 }}>{text}</span>,
    },
    {
      title: "操作",
      key: "createDt",
      dataIndex: "createDt",
      align: "center",
      render: (text) => (
        <Space size="middle">
          <a style={{ fontSize: 12 }}>重置操作密码</a>
          <a style={{ fontSize: 12 }}>编辑</a>
          <a style={{ fontSize: 12, color: "red" }}>删除</a>
        </Space>
      ),
    },
  ];

  const data: any[] = [
    {
      userId: 1,
      realName: "张三",
      commit: "登录成功",
      ip: "192.168.0.1",
      createDt: "2021-08-01 12:00:00",
    },
    {
      userId: 2,
      realName: "李四",
      commit: "导出文件",
      ip: "192.168.0.2",
      createDt: "2021-08-01 12:00:00",
    },
    {
      userId: 3,
      realName: "王老吴",
      commit: "登录成功",
      ip: "192.168.0.3",
      createDt: "2021-08-01 12:00:00",
    },
  ];

  return (
    <div style={{ padding: 30, paddingTop: 6 }}>
      <Tabs
        defaultActiveKey="1"
        type="card"
        items={[
          {
            key: "1",
            label: "个人中心",
            children: <PersonalCenter />,
          },
          {
            key: "2",
            label: "授权人员管理",
            children: <AdminUser />,
          },
          {
            key: "3",
            label: "日志审计",
            children: <Logs />,
          },
        ]}
      ></Tabs>
    </div>
  );
}
