// @ts-ignore
/* eslint-disable */
// API 更新时间：
// API 唯一标识：
import * as login from "./apis/login";
import * as captcha from "./apis/captcha";
import * as logs from "./apis/logs";
import * as user from "./apis/user";
import * as role from "./apis/role";
import * as dictionary from "./apis/dictionary";
import * as opAudit from "./apis/opAudit";

const SERVICE_API = { login, captcha, logs, user, role, dictionary, opAudit };
export default SERVICE_API;
