import dateJS from "@/utils/date";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Space,
  Table,
  Tag,
  Modal,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import imgUrl from "../../assets/images/home_bg.svg";
import defaultAvatar from "../../assets/logo.png";
import bannerIcon from "../../assets/logo.png";
import articleIcon from "../../assets/logo.png";
import agencyIcon from "../../assets/logo.png";
import settingIcon from "../../assets/logo.png";
import styles from "./index.module.css";
import {
  DeliveredProcedureOutlined,
  PlusOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import SERVICE_API from "@/api";
import UserInfo from "./components/UserInfo";

export default function AdminUser() {
  const [visible, setVisible] = useState(false);
  const [visibleTwo, setVisibleTwo] = useState(false);
  const [cuRecord, setCuRecord] = useState({});
  const [roleEnum, setRoleEnum] = useState({});
  const [pageParam, setPageParam] = useState({
    pageSize: 10,
    current: 1,
  });
  const [statusEnum, setStatusEnum] = useState({});
  const [userList, setUserList] = useState([]);

  // 获取用户列表
  const getUserList = async (params: any) => {
    const res = await SERVICE_API.user.getUserList({
      ...params,
      pageSize: 1000,
      current: 1,
      phoneNo: params.loginPhone,
    });
    setPageParam(params);
    if (res?.code === 0) {
      setUserList(res?.data?.list);
    }
    return {
      success: res?.code === 0,
      data: res?.data?.list,
      total: res?.data?.total,
    };
  };

  const getRoleList = async () => {
    const res = await SERVICE_API.role.getRoleList({});
    const roleObj = {};
    res?.data?.forEach((item: any) => {
      roleObj[item.roleId] = {
        text: item.roleName,
        status: item.roleId,
      };
    });
    setRoleEnum(roleObj);
  };
  // 获取状态
  const getStatus = async () => {
    const statusRes = await SERVICE_API.dictionary.getStatus({
      type: "org_status",
    });
    const statusObj = {};
    statusRes.data.list.forEach((item: any) => {
      return (statusObj[item.id] = {
        status: item.id,
        text: item.label,
        color: item.id == "9001" ? "#00cf40" : "#ff0000",
      });
    });
    setStatusEnum(statusObj);
  };
  // 修改用户状态  冻结-解冻用户
  const modifyStatus = async (value: any) => {
    if (value.status === "9002") {
      const res = await SERVICE_API.user.modifyStatus({
        userId: value.userId,
        freeze: false,
      });
      if (res.code === 0) {
        message.success("解冻成功");
      }
    } else if (value.status === "9001") {
      const res = await SERVICE_API.user.modifyStatus({
        userId: value.userId,
        freeze: true,
      });
      if (res.code === 0) {
        message.success("冻结成功");
      }
    }
  };
  const refreshPage = () => {};
  useEffect(() => {
    getRoleList();
    // getStatus();
    getUserList({});
  }, []);
  // 删除用户列表
  const delUser = async (value: any) => {
    const res = await SERVICE_API.user.delUserList({
      userId: value.userId,
    });
    if (res.code === 0) {
      message.success("删除成功");
      getUserList(pageParam);
    }
  };

  // 重置密码
  const resetPassword = async (value: any) => {
    const res = await SERVICE_API.user.resetPasswordByAdmin({
      userId: value.userId,
      loginName: value.loginName,
      loginPhone: value.loginPhone,
    });
    if (res.code === 0) {
      message.success("重置成功");
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: "用户ID",
      dataIndex: "userId",
      key: "userId",
      align: "center",
      render: (text) => <a style={{ fontSize: 12 }}>{text}</a>,
    },
    {
      title: "姓名",
      dataIndex: "realName",
      key: "realName",
      align: "center",
      render: (text) => <span style={{ fontSize: 12 }}>{text}</span>,
    },
    {
      title: "登录账号",
      dataIndex: "loginName",
      key: "loginName",
      align: "center",
      render: (text) => <span style={{ fontSize: 12 }}>{text}</span>,
    },
    {
      title: "操作",
      key: "createDt",
      dataIndex: "createDt",
      align: "center",
      width: 160,
      render: (text, record) => {
        return (
          record.adminFlag != 1 && (
            <Space size="middle">
              <a
                style={{ fontSize: 12 }}
                onClick={() =>
                  Modal.confirm({
                    title: "重置密码",
                    content: "确定重置该用户密码吗？",
                    centered: true,
                    onOk: () => {
                      resetPassword(record);
                    },
                  })
                }
              >
                重置密码
              </a>
              <a
                style={{ fontSize: 12 }}
                onClick={() => {
                  setVisibleTwo(true);
                  setCuRecord(record);
                }}
              >
                编辑
              </a>
              <a
                style={{ fontSize: 12, color: "red" }}
                onClick={() =>
                  Modal.confirm({
                    title: "删除",
                    content: "确定删除该用户吗",
                    centered: true,
                    onOk: () => {
                      delUser(record);
                    },
                  })
                }
              >
                删除
              </a>
            </Space>
          )
        );
      },
    },
  ];

  return (
    <div>
      <Card
        title="审核员管理"
        style={{ marginBottom: 20 }}
        bodyStyle={{ padding: 15 }}
        extra={
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setVisibleTwo(true);
              setCuRecord({});
            }}
          >
            新增审核员
          </Button>
        }
      >
        <Table
          columns={columns}
          dataSource={userList}
          size="small"
          scroll={{ y: 350 }}
          bordered
          rowKey={(record) => record.userId}
          pagination={false}
        />
      </Card>
      <Modal
        title="新增审核员"
        open={visibleTwo}
        centered
        footer={null}
        width={500}
        onCancel={() => setVisibleTwo(false)}
        destroyOnClose
      >
        <UserInfo
          record={cuRecord}
          onFinish={() => {
            setVisibleTwo(false);
            getUserList(pageParam);
          }}
        />
      </Modal>
    </div>
  );
}
