import { request } from "../request";

// 获取角色列表
/** msgSecCheck POST /wx/mini/msgSecCheck */
export async function getRoleList(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/org/role/list", {
    method: "POST",
    // headers: {
    //   'Content-Type': 'application/json',
    // },
    data: body,
    ...(options || {}),
  });
}

// 新增角色
export async function addRole(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/org/role/save", {
    method: "POST",
    // headers: {
    //   'Content-Type': 'application/json',
    // },
    data: body,
    ...(options || {}),
  });
}

// 删除角色
export async function deleteData(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/org/role/del", {
    method: "POST",
    // headers: {
    //   'Content-Type': 'application/json',
    // },
    data: body,
    ...(options || {}),
  });
}

// 修改角色
export async function upRoleData(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/org/role/save", {
    method: "POST",
    // headers: {
    //   'Content-Type': 'application/json',
    // },
    data: body,
    ...(options || {}),
  });
}

// 获取系统菜单列表
export async function getSysMenu(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/sys/menu/list", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

// 编辑系统菜单
export async function saveSysMenu(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/sys/menu/save", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

// 删除系统菜单
export async function delSysMenu(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/sys/menu/del", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

// 获取菜单列表
export async function getRoleMenu(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/org/role/menu/list", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

// 获取系统功能点
export async function getFunction(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/sys/func/list", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

// 获取菜单功能点
export async function getRoleFunction(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/org/role/func/list", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

// 保存菜单
export async function saveMenu(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/org/role/menu/conf", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

// 保存功能
export async function saveFunc(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/org/role/func/conf", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

// 获取全部权限列表(暂时没用这个接口)
export async function AuthoritySet(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/org/role/func/list", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

// 根据机构类型获取模板菜单
export async function TplMenu(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/tpl/menu", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

// 根据机构类型获取模板功能点
export async function TplFunc(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/tpl/func", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

// 获取超级管理员菜单
export async function AdminMenu(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/org/role/admin-menu", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

// 获取超级管理员功能点
export async function AdminFunc(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/org/role/admin-func", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

// 获取超级管理员菜单(机构权限设置)
export async function OrgAdminMenu(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/org/role/org/admin-menu", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

// 获取超级管理员功能点(机构权限设置)
export async function OrgAdminFunc(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/org/role/org/admin-func", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

// 获取超级管理员
export async function GetAdminRole(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/org/role/admin", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

// 获取机构角色现有权限
export async function getRolePerm(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/org/role/perm/list", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

// 批量修改机构角色数据权限
export async function saveRolePermBatch(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/org/role/perm/conf", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

// 按模板重置机构超级管理员权限
export async function resetMenuFromTpl(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/org/role/rest/menu", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}
