/**
 * @description 获取url的query参数
 * @param {String} url url
 * @return {Object} 转换后的k-v对象
 */
export const parseQueryString = function parseQueryString(url: string) {
  const obj = {};
  const paraString = url.substring(url.indexOf("?") + 1, url.length).split("&");
  paraString.forEach((item) => {
    const keyValue = item.split("=");
    const key = keyValue[0];
    // 解码
    const value = decodeURIComponent(keyValue[1]);
    obj[key] = value;
  });
  return obj;
};

// 获取区域regionId
export const region = function region() {
  const storage = localStorage.getItem("orgId");
  const infoObj = storage ? JSON.parse(storage) : {};
  const regionId = infoObj?.regionId;
  return regionId;
};

/**
 * @description url
 * @param {Object} params url
 * @return null
 */
export const replaceLocationQuery = function replaceLocationQuery(params: any) {
  let url = `${window.location.href.split("?")[0]}`;
  Object.keys(params).forEach((item, index) => {
    if (index === 0) {
      url = `${url}?${item}=${params[item]}`;
    } else {
      url = `${url}&${item}=${params[item]}`;
    }
  });
  window.history.replaceState({ url: url }, "", url);
};

/**
 * @description list递归生成树形结构数据
 * @param {Object} list list
 * @return list
 */
export const listToTree = (list: any[], idKey: string, pidKey: string) => {
  // 删除 所有 children,以防止多次调用
  list.forEach((item) => {
    delete item.children;
  });

  // 将数据存储为k-v对map
  const map = {};
  list.forEach((item) => {
    map[item[idKey]] = item;
  });
  const val: any[] = [];
  list.forEach((item) => {
    // 以当前遍历项的pid,去map对象中找到索引的id
    const parent = map[item[pidKey]];
    // 把当前项分配给其父级项，作为children
    if (parent) {
      (parent.children || (parent.children = [])).push(item);
    } else {
      //如果没有在map中父级，说明它是顶级，直接推送到结果数组中
      val.push(item);
    }
  });
  return val;
};

/**
 * @description 比较两个list的值是否完全相等（不含顺序）
 * @param {Object} list list
 * @return list
 */
export const isArrayValueEqual = function (left: any[], right: any[]) {
  if (left.length != right.length) return false;
  let a = 0;
  left.forEach((leftItem) => {
    if (right.indexOf(leftItem) != -1) {
      a++;
    }
  });
  if (a === left.length) return true;
  return false;
};

/**
 * 取二维数组最小火最大值
 */
export const getMinOrMax = (arr: any[], key: any, type: string) => {
  let min = arr[0][key];
  let max = arr[0][key];
  arr.forEach((item: any) => {
    if (item[key] < min) {
      min = item[key];
    }
    if (item[key] > max) {
      max = item[key];
    }
  });
  if (type === "min") {
    return min;
  } else {
    return max;
  }
};

/**
 * @description 从身份证号码中获取出生日期及性别及年龄
 * @param {String} idCard 身份证号码
 */
export const getBirthdayAndSexAndAge = function (idCard: string) {
  let birthday = "";
  let sex = "0";
  let age = 0;
  if (idCard != null && idCard !== "") {
    if (idCard.length === 15) {
      birthday = "19" + idCard.slice(6, 12);
    } else if (idCard.length === 18) {
      birthday = idCard.slice(6, 14);
      sex = 2 - (Number(idCard.slice(-2, -1)) % 2) + "";
      // 计算年龄
      const now = new Date();
      age = now.getFullYear() - Number(birthday.slice(0, 4));
    }
    birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-");
  }
  return {
    birthday,
    age,
    sex,
  };
};

/**
 * @description 菜单id生成器
 */
export const menuDataGenerator = function (menu: any[]) {
  const list: any[] = [];
  let lvNum1 = 1;
  let lvNum2 = 1;
  let lvNum3 = 1;
  const menuTemp = menu.filter((item: any) => item.name);
  menuTemp.forEach((item) => {
    if (item.path) {
      const pathArr = item.path.slice(1).split("/");
      switch (pathArr.length) {
        case 1:
          item.id = "M" + `00${lvNum1}`.slice(-3);
          lvNum1++;
          break;
        case 2:
          item.id = item.pid + `00${lvNum2}`.slice(-3);
          lvNum2++;
          break;
        case 3:
          item.id = item.pid + `00${lvNum3}`.slice(-3);
          lvNum3++;
          break;
        default:
          break;
      }
      // 处理子菜单，赋予pid
      let newChildren: any[] = [];
      if (item.routes && item.routes.length > 0) {
        newChildren = item.routes.map((child: any) => ({
          ...child,
          pid: item.id,
        }));
      }

      list.push({
        ...item,
        pathArr: item.path.slice(1).split("/"),
        menuId: item.id,
        routes: newChildren,
        deep: pathArr.length,
      });
      if (item.routes && item.routes.length > 0) {
        list.push(...menuDataGenerator(newChildren));
      }
    }
  });
  return list;
};

/**
 * @description 过滤html标签
 * @param {String} str 字符串
 * @return {String} 过滤后的字符串
 */
export const filterHtmlTag = function (str: string) {
  return str.replace(/<[^>]+>/g, "");
};

/**
 * @description UUID生成器
 * @param {String} len 长度
 * @param {String} radix 基数
 * @return {String} UUID
 * /
 */
export const genUuid = function (len: number, radix: number) {
  const chars =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split("");
  const uuid: string[] = [];
  let i: number;
  radix = radix || chars.length;
  if (len) {
    // Compact form
    for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
  } else {
    // rfc4122, version 4 form
    let r: number;
    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
    uuid[14] = "4";
    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36; i++)
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16);
        uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r];
      }
  }
  return uuid.join("");
};
