import umiRequest from "umi-request";
import { requestInterceptor, responseInterceptor } from "./requestInterceptors";

interface ResponseData {
  code: number;
  data: any;
  msg: string;
}
export async function request(
  url: string,
  options: {
    method?: string;
    headers?: any;
    data?: any;
    body?: any;
    params?: any;
  }
): Promise<ResponseData> {
  // const baseUrl = "https://ny-iot.quchutech.com/admin-api";
  // if (!url.startsWith("http")) {
  //   url = baseUrl + url;
  // }
  options = {
    method: "POST",
    // headers: {
    //   "Content-Type": "application/json",
    //   "QC-TOKEN": "eyJhbG",
    // },
    ...options,
    data: options.data || options.body,
  };
  // electron的baseUrl支持配置
  if (window.electron) {
    let baseUrl = window.electron.store.get("baseUrl");
    url = baseUrl + url;
  }
  const newOptions = requestInterceptor(url, options);
  // electron 环境下使用 electron 提供的网络请求
  if (window.electron) {
    return new Promise<ResponseData>((resolve, reject) => {
      window.electron
        .netRequest(newOptions.url, newOptions.options, newOptions.options.data)
        .then((res: any) => {
          const data = responseInterceptor(res);
          resolve(data);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  }

  // 普通浏览器环境
  if (!options.method || options.method === "POST") {
    return new Promise<ResponseData>((resolve, reject) => {
      umiRequest(newOptions.url, newOptions.options)
        .then((res) => {
          const data = responseInterceptor(res);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
