import {
  Button,
  Input,
  Modal,
  Progress,
  Row,
  Select,
  Space,
  Upload,
  UploadProps,
  message,
} from "antd";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import styles from "./index.module.css";
import {
  AESEncData,
  aesEncryptFileByChunk,
  computeMD5,
  genIv,
  getFileMd5ByChunck,
} from "@/utils/CryptoUtil";
import SERVICE_API from "@/api";
import hex_md5 from "@/utils/md5";

const { Dragger } = Upload;
export default function Expenses() {
  const boxRef = useRef<any>(null);
  const fileSaveHandler = useRef<any>(null);
  const [fileList, setFileList] = useState<any>([]);
  const [progressObj, setProgressObj] = useState<any>({});
  const [progressVisible, setProgressVisible] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(false);
  const [authVisible, setAuthVisible] = useState<boolean>(false);
  const [authed, setAuthed] = useState<boolean>(false);
  const [authUser, setAuthUser] = useState<any>({ userId: "", userName: "" });
  const [auditorList, setAuditorList] = useState<any>([]);
  // 校验状态
  const [validateStatus, setValidateStatus] = useState<any>({});
  // 表单数据
  const [formData, setFormData] = useState<any>({});
  const [authFormData, setAuthFormData] = useState<any>({});
  let macAddress = "";
  if (window.electron) {
    window.electron.plat.getMacAddress().then((res: any) => {
      macAddress = res;
    });
  }

  // 获取用户列表
  const getAuditorList = async () => {
    const res = await SERVICE_API.user.getUserListNoAuth({
      pageSize: 1000,
      current: 1,
    });
    if (res?.code === 0) {
      const list =
        res?.data?.list?.map((item: any) => {
          return {
            value: item.userId,
            label: item.userName,
          };
        }) || [];
      setAuditorList(list);
      setAuthFormData({ ...authFormData, adminUser: list[0]?.value });
    }
    return {
      success: res?.code === 0,
      data: res?.data?.list,
      total: res?.data?.total,
    };
  };

  // 表单数据校验
  const validateForm = () => {
    const errorList = [];
    Object.keys(validateStatus).forEach((key) => {
      if (validateStatus[key]?.status === "error") {
        errorList.push(key);
      }
    });
    if (errorList.length > 0) {
      message.error("请检查表单");
      return false;
    }
    return true;
  };

  const handleFileSingle = async (file: any, fileId: string | number) => {
    if (!file) {
      message.error("请选择文件");
      return;
    }
    if (!formData.password) {
      message.error("请输入密码");
      return;
    }
    let AESKey = formData.password;
    // 不足16位的密码补0
    if (formData.password?.length < 16) {
      AESKey = formData.password.padEnd(16, "\0");
    }
    const AESIv = genIv(AESKey);
    const encFn = (plianText: any) => {
      const encData = AESEncData(plianText, AESKey, AESIv);
      return encData;
    };
    const regex = /(.*)\.(.*)$/;
    const match = file.name.match(regex);
    const name = match[1];
    const extension = match[2];
    // 文件流处理
    const opts = {
      suggestedName: name + "_encrypted." + extension,
      types: [],
    };
    let writableStream: { close: () => any; write: (arg0: any) => any };
    let streamHandler: (stream: any, progress: number) => void;
    if (!window.electron) {
      try {
        // 请求用户授权访问文件系统
        // const handle = await (window as any).showSaveFilePicker(opts); // 弹出文件选择框，兼容性问题
        const handle = fileSaveHandler.current;
        if (!handle) {
          message.error("请选择保存目录");
          return;
        }
        setProgressVisible(true);
        // 创建一个WritableStream对象，用于写入数据到本地文件
        writableStream = await handle?.createWritable();
      } catch (e) {
        console.log("报错了", e.message);
        if (e.message.indexOf("Failed to execute 'showSaveFilePicker'") > -1) {
          message.error("请允许访问文件系统");
        }
        return;
      }
      streamHandler = async (stream: any, progress: number) => {
        // console.log("stream", stream, progress);
        setProgressObj((prev: any) => {
          return {
            ...prev,
            [fileId]: {
              progress: Number((progress * 100).toFixed(2)),
              fileName: file.name,
            },
          };
        });
        if (!stream && progress === 1) {
          // 关闭写入流
          await writableStream.close();
          fileSaveHandler.current = null;
          return;
        }
        // 写入本地文件
        await writableStream.write(stream);
      };
    }

    if (window.electron) {
      setProgressVisible(true);
      const fs = window.electron.fs;
      streamHandler = async (stream: any, progress: number) => {
        setProgressObj((prev: any) => {
          return {
            ...prev,
            [fileId]: {
              progress: Number((progress * 100).toFixed(2)),
              fileName: file.name,
            },
          };
        });
        let isEnd = false;
        if (!stream && progress === 1) {
          isEnd = true;
        }
        fs.writeStream({
          path: formData.saveDir,
          options: { flags: "a" },
          data: stream,
          end: isEnd,
        });
      };
    }

    let ret = await aesEncryptFileByChunk(file, encFn, streamHandler);
    return;
  };

  const handleFileChanged = async (files: any[]) => {
    const obj = {};
    for (let i = 0; i < files.length; i++) {
      obj[i] = {
        progress: 0,
        fileName: files[i].name,
      };
    }
    setProgressObj(obj);
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      await handleFileSingle(file, i);
    }
    reportExportLog(files);
  };

  // 上报导出日志
  const reportExportLog = async (fileList: any) => {
    const res = await SERVICE_API.opAudit.reportExportLog({
      opType: "文件加密导出",
      macAddress: macAddress,
      fileList: fileList.map((item: any) => {
        return {
          fileName: item.name,
          filePath: item.path || "",
          fileMd5: item.md5 || "",
        };
      }),
      auditUser: authUser,
    });
    if (res?.code === 0) {
      console.log("上报成功");
    }
  };

  // 校验管理员密码
  const validateAdminPassword = async () => {
    if (!authFormData.adminUser) {
      message.error("请选择审核人");
      return false;
    }
    const password = authFormData.adminPassword;
    if (!password) {
      message.error("请输入密码");
      return false;
    }
    const res = await SERVICE_API.opAudit.auth({
      userId: authFormData.adminUser,
      password: password ? hex_md5(password) : "",
      auditContent: {
        opType: "文件加密导出",
        macAddress: macAddress,
        fileList: fileList.map((item: any) => {
          return {
            fileName: item.name,
            filePath: item.path || "",
            fileMd5: item.md5 || "",
          };
        }),
      },
    });
    if (res?.code === 0) {
      setAuthed(true);
      setAuthUser({
        userId: authFormData.adminUser,
        userName: auditorList.find(
          (item: any) => item.value === authFormData.adminUser
        )?.label,
      });
      setAuthVisible(false);
      setAuthFormData({ ...authFormData, adminPassword: "" });
      return true;
    }
    // message.error("授权密码错误");
    return false;
  };

  // 执行加密导出
  const handleExport = async () => {
    if (!validateForm()) {
      return;
    }
    if (!authed) {
      message.error("请先完成授权");
      return;
    }
    handleFileChanged(fileList);
  };

  // 选择目录
  const chooseDir = async () => {
    const file = fileList[0];
    if (!file) {
      message.error("请先选择文件");
      return;
    }
    const regex = /(.*)\.(.*)$/;
    const match = file.name.match(regex);
    let name = match[1];
    const extension = match[2];
    name = name.replace(/_encrypted$/, "");
    if (window.electron) {
      const res = await window.electron.dialog.saveFile({
        defaultPath: name + "_encrypted." + extension,
        // properties: ["openDirectory"],
      });
      setFormData({ ...formData, saveDir: res });
      return;
    }
    // 文件流处理
    const opts = {
      suggestedName: name + "_encrypted." + extension,
      types: [],
    };
    try {
      // 请求用户授权访问文件系统
      const handle = await (window as any).showSaveFilePicker(opts); // 弹出文件选择框，兼容性问题
      fileSaveHandler.current = handle;
      setFormData({ ...formData, saveDir: opts.suggestedName });
      return handle;
    } catch (e) {
      console.log("报错了", e.message);
      if (e.message.indexOf("Failed to execute 'showSaveFilePicker'") > -1) {
        message.error("请允许访问文件系统");
      }
      return null;
    }
  };

  // 密码强度校验
  const validatePassword = (password: string) => {
    // 不能是连续3位数字
    for (let i = 0; i < password.length - 2; i++) {
      if (
        password.charCodeAt(i) === password.charCodeAt(i + 1) - 1 &&
        password.charCodeAt(i) === password.charCodeAt(i + 2) - 2
      ) {
        setValidateStatus({
          ...validateStatus,
          password: {
            message: "密码不能包含3位及以上连续数字或字母，如：abc、123",
            status: "error",
          },
        });
        return false;
      }
      if (password[i] === password[i + 1] && password[i] === password[i + 2]) {
        setValidateStatus({
          ...validateStatus,
          password: {
            message: "密码不能包含连续3位相同字符，如：aaa、111",
            status: "error",
          },
        });
        return false;
      }
    }
    const reg2 = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/g;
    if (!reg2.test(password)) {
      setValidateStatus({
        ...validateStatus,
        password: {
          message: "密码必须是8-16位，必须包含字母和数字",
          status: "error",
        },
      });
      return false;
    }

    return true;
  };

  // onFormChange
  const onFormChange = (key: string, value: any) => {
    setFormData({ ...formData, [key]: value });
  };

  useEffect(() => {
    // 所有文件导出完成
    const progressArr = Object.keys(progressObj).map((key) => {
      return progressObj[key];
    });
    const completedT = progressArr.every((item) => item.progress === 100);
    setCompleted(completedT);
  }, [progressObj]);

  useEffect(() => {
    if (authVisible) {
      getAuditorList();
    }
  }, [authVisible]);

  const props: UploadProps = {
    name: "file",
    fileList: fileList,
    multiple: false,
    action: "",
    listType: "picture",
    // itemRender: (originNode, file, currFileList) => {
    //   console.log("itemRender", originNode, file, currFileList);
    //   return originNode;
    // },
    beforeUpload: async (file, fileList) => {
      // console.log("beforeUpload", file, fileList);
      // setFileList(fileList);
      setFileList([file]);
      setTimeout(() => {
        // 获取文件md5
        computeMD5(file).then((md5) => {
          (file as any).md5 = md5;
          setFileList([file]);
        });
      }, 500);
      return false;
    },
    onDrop(e) {
      // console.log("Dropped files", e.dataTransfer.files);
    },
    onChange() {
      setAuthed(false);
    },
    onRemove(file) {
      setFileList([]);
    },
  };

  return (
    <>
      <main style={{ padding: 30, paddingTop: 6 }} ref={boxRef}>
        <div className={styles.form_item}>
          <div className={styles.label}>选择文件</div>
          {/* <Input style={{ width: "100%" }} placeholder="请选择需要加密的文件" /> */}
          <Dragger {...props}>
            {fileList.length === 0 && (
              <div style={{ padding: "16px 0" }}>
                <p className="ant-upload-drag-icon" style={{ marginBottom: 6 }}>
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text" style={{ fontSize: 14 }}>
                  点击或拖动文件到此区域完成选择
                </p>
                <p className="ant-upload-hint" style={{ fontSize: 12 }}>
                  支持单个文件导出，严禁导出机密文件和其他禁止文件
                </p>
              </div>
            )}
          </Dragger>
        </div>
        <div className={styles.form_item}>
          <div className={styles.label}>保存目录</div>
          <Input
            style={{ width: "100%" }}
            value={formData.saveDir}
            addonAfter={<a onClick={chooseDir}>选择</a>}
            disabled
          />
        </div>
        <div className={styles.form_item}>
          <div className={styles.label}>加密密码</div>
          <Input.Password
            style={{ width: "100%" }}
            maxLength={16}
            minLength={8}
            status={validateStatus.password?.status}
            value={formData.password}
            onChange={(e) => {
              // 只能字母和数字
              const reg = /^[0-9a-zA-Z]*$/g;
              if (!reg.test(e.target.value)) {
                setValidateStatus({
                  ...validateStatus,
                  password: {
                    message: "密码只能是字母和数字",
                    status: "error",
                  },
                });
                return;
              }
              if (validatePassword(e.target.value)) {
                setValidateStatus({
                  ...validateStatus,
                  password: undefined,
                });
              }
              onFormChange("password", e.target.value);
            }}
          />
          {validateStatus.password?.status === "error" && (
            <div className={styles.tips} style={{ color: "red" }}>
              {validateStatus.password?.message}
            </div>
          )}
          <div className={styles.tips}>
            密码只能是8-16位大小写字母和数字，程序不会保存密码，请自行妥善保存。
          </div>
        </div>
        <div className={styles.form_item}>
          <div className={styles.label}>授权审核</div>
          <Row
            style={{
              width: "100%",
              height: "80px",
              background: "#fafafa",
              padding: "0 20px",
            }}
            align="middle"
            justify="space-between"
          >
            {authed ? (
              <Space style={{ color: "#00b22f" }}>
                <CheckCircleOutlined />
                <span>【{authUser.userName}】已授权</span>
              </Space>
            ) : (
              <Space style={{ color: "red" }}>
                <ExclamationCircleOutlined />
                <span>未授权</span>
              </Space>
            )}
            {!authed ? (
              <Button
                type="primary"
                style={{
                  background: "#00b22f",
                  borderColor: "#00b22f",
                }}
                onClick={() => {
                  if (fileList.length === 0) {
                    message.error("请先选择文件");
                    return;
                  }
                  setAuthVisible(true);
                }}
              >
                授权
              </Button>
            ) : (
              <a
                onClick={() => {
                  if (fileList.length === 0) {
                    message.error("请先选择文件");
                    return;
                  }
                  setAuthVisible(true);
                }}
              >
                重新授权
              </a>
            )}
          </Row>
        </div>
        <Button
          type="primary"
          style={{ width: "100%", marginTop: 12 }}
          disabled={!authed}
          size="large"
          onClick={() => handleExport()}
        >
          开始导出
        </Button>
      </main>
      <Modal
        title="导出中..."
        open={progressVisible}
        centered
        closable={false}
        maskClosable={false}
        footer={
          <Row justify="center" align="middle">
            <Button
              style={{
                // background: "#00b22f",
                // borderColor: "#00b22f",
                width: "calc(100%)",
              }}
              disabled={!completed}
              type="primary"
              size="large"
              onClick={() => {
                setProgressObj({});
                setProgressVisible(false);
                setFileList([]);
                setFormData({});
                setAuthed(false);
                setAuthUser({ userId: "", userName: "" });
              }}
            >
              完成
            </Button>
          </Row>
        }
        cancelText="取消"
        okText="完成"
        okButtonProps={{ disabled: !completed }}
        onCancel={() => {
          Modal.confirm({
            title: "确认取消导出？",
            content: "取消后需要重新授权",
            centered: true,
            onOk: () => {
              setProgressVisible(false);
              setProgressObj({});
            },
            okText: "确认",
            cancelText: "取消",
          });
        }}
      >
        <div
          style={{
            marginBottom: 20,
            fontSize: 14,
            background: "#fafafa",
            padding: "10px 15px",
            borderRadius: 4,
          }}
        >
          <div style={{ marginBottom: 12, fontWeight: 600, color: "#666" }}>
            执行进度
          </div>
          {Object.keys(progressObj).map((key) => {
            const item = progressObj[key];
            return (
              <div
                key={key}
                style={{
                  marginBottom: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ flex: 1 }}>
                  <a
                    style={{ marginRight: 14, fontSize: 14, cursor: "default" }}
                  >
                    {item.fileName}
                  </a>
                  <div style={{ width: "calc" }}>
                    <Progress percent={item.progress} showInfo={false} />
                  </div>
                </div>
                {/* <div style={{ flex: "0 0 60px", padding: 10 }}>
                <div
                  style={{
                    width: 40,
                    height: 40,
                    background: "#f5f5f5",
                    borderRadius: 4,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <a>保存</a>
                </div>
              </div> */}
              </div>
            );
          })}
        </div>
      </Modal>
      <Modal
        title="您正在进行文件导出授权"
        open={authVisible}
        centered
        closable={false}
        footer={null}
        width={420}
      >
        <div
          style={{
            marginBottom: 20,
            fontSize: 14,
            background: "#fafafa",
            padding: "10px 15px",
            borderRadius: 4,
          }}
        >
          <div style={{ marginBottom: 12, fontWeight: 600, color: "#666" }}>
            导出的文件
          </div>
          <ul>
            {fileList.map((item: any) => {
              return (
                <li key={item.uid}>
                  <a>{item.name}</a>
                </li>
              );
            })}
          </ul>
        </div>
        <div className={styles.form_item}>
          <div className={styles.label} style={{ color: "#666" }}>
            审核人
          </div>
          <Select
            size="large"
            options={auditorList}
            style={{ width: "100%" }}
            value={authFormData.adminUser}
            onChange={(value) => {
              setAuthFormData({ ...authFormData, adminUser: value });
            }}
          />
        </div>
        <div className={styles.form_item}>
          <div className={styles.label} style={{ color: "#666" }}>
            授权密码
          </div>
          <Input.Password
            size="large"
            style={{ width: "100%" }}
            visibilityToggle={false}
            value={authFormData.adminPassword}
            onChange={(e) => {
              setAuthFormData({
                ...authFormData,
                adminPassword: e.target.value,
              });
            }}
          />
        </div>
        <Row justify="center" align="middle" style={{ marginTop: 40 }}>
          <Button
            style={{ marginRight: 10, width: "calc(50% - 10px)" }}
            size="large"
            onClick={() => {
              setAuthVisible(false);
            }}
          >
            取消
          </Button>
          <Button
            style={{
              background: "#00b22f",
              borderColor: "#00b22f",
              marginLeft: 10,
              width: "calc(50% - 10px)",
            }}
            type="primary"
            size="large"
            onClick={() => {
              validateAdminPassword();
            }}
          >
            确认授权
          </Button>
        </Row>
      </Modal>
    </>
  );
}
