import { useRef, useState } from "react";
import { Row, message } from "antd";
import type { ProFormInstance } from "@ant-design/pro-form";
import ProForm, { ProFormDigit } from "@ant-design/pro-form";
import { DrawerForm } from "@ant-design/pro-form";
import { ProFormSelect } from "@ant-design/pro-form";
import {
  ProFormText,
  // DrawerForm,
} from "@ant-design/pro-form";
import { useEffect } from "react";
import SERVICE_API from "@/api";

// 传递过来的数据类型
export type PropsType = {
  record?: any;
  onCancel?: () => void;
  onFinish?: () => void;
};

export default function UserInfo(props: PropsType) {
  const formRef = useRef<ProFormInstance>();
  const [roleEnum, setRoleEnum] = useState([]);
  // 获取角色列表
  const getRoleList = async () => {
    const res = await SERVICE_API.role.getRoleList({});
    // 排除超级管理员
    const roleList = res?.data.filter((item: any) => item.adminFlag !== "1");
    const roleArr = roleList.map((item: any) => {
      return {
        value: item.roleId,
        label: item.roleName,
      };
    });
    setRoleEnum(roleArr);
  };

  // 修改用户数据
  const onFinish = async (params: any) => {
    const value = {
      ...params,
      nickName: params.realName,
      userId: props.record?.userId,
    };
    const res = await SERVICE_API.user.saveUser(value);
    if (res?.data) {
      const roleRes = await SERVICE_API.user.addUserRole({
        roleId: params.roleId,
        userId: String(res.data),
      });
      if (roleRes.code === 0) {
        props?.onCancel?.();
        props?.onFinish?.();
        message.success("新增成功");
      }
    } else if (res.code === 0) {
      const roleRes = await SERVICE_API.user.saveUserRole({
        roleId: params.roleId,
        userId: props.record?.userId,
      });
      if (roleRes.code === 0) {
        props?.onCancel?.();
        props?.onFinish?.();
        message.success("修改成功");
      }
    }
  };

  useEffect(() => {
    getRoleList();
    if (formRef.current!) {
      // formRef.current!.resetFields()
      formRef.current!.setFieldsValue({
        ...props.record,
      });
      return () => {};
    }
  }, []);
  return (
    <div>
      <ProForm
        layout="horizontal"
        labelCol={{ span: 5 }}
        formRef={formRef}
        // 确认
        onFinish={onFinish}
        submitter={{
          render: (_props, dom) => {
            return (
              <Row
                style={{
                  border: "none",
                  justifyContent: "center",
                  marginLeft: "0px",
                }}
              >
                {dom[0]}
                <div style={{ width: 24 }} />
                {dom[1]}
              </Row>
            );
          },
        }}
      >
        <ProFormText
          name="loginName"
          label="登录账号"
          placeholder="请填写"
          rules={[{ required: true, message: "这是必填项" }]}
        />
        {/* <ProFormText
          width="lg"
          name="nickName"
          label="昵称"
          placeholder="请输入昵称"
          rules={[{ required: true, message: '这是必填项' }]}
        /> */}
        <ProFormText
          name="realName"
          label="姓名"
          placeholder="请输入真实姓名"
          rules={[{ required: true, message: "这是必填项" }]}
        />
        {/* <ProFormDigit
          width="lg"
          name="phoneNo"
          label="联系电话"
          placeholder="必须是数字"
          min={0}
          rules={[{ required: true, message: "这是必填项" }]}
        /> */}
        <ProFormSelect
          options={roleEnum}
          name="roleId"
          label="角色"
          rules={[{ required: true, message: "这是必填项" }]}
        />
        {/* <ProFormDigit
          width="lg"
          name="age"
          label="年龄"
          placeholder="请输入年龄"
          min={0}
        />
        <ProFormText
          width="lg"
          name="birth"
          label="生日"
          placeholder="请输入生日"
        /> */}
        {/* <ProFormSelect
          width="lg"
          options={[
            {
              value: "1",
              label: "男",
            },
            {
              value: "2",
              label: "女",
            },
          ]}
          name="sex"
          label="性别"
        /> */}
        <ProFormText name="descp" label="描述" placeholder="请输入描述" />
      </ProForm>
    </div>
  );
}
