import { request } from "../request";

// 获取操作日志列表
export async function getOpLogList(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/op-log/list", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}
