import {
  Button,
  Input,
  Modal,
  Progress,
  Row,
  Upload,
  UploadProps,
  message,
} from "antd";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import styles from "./index.module.css";
import CryptoJS from "crypto-js";
import {
  AESDecData,
  AESEncData,
  ArrayBufferToWordArray,
  WordArrayToArrayBuffer,
  aesDecryptFileByChunk,
  aesEncryptFileByChunk,
  genIv,
} from "@/utils/CryptoUtil";
import SERVICE_API from "@/api";

const { Dragger } = Upload;

export default function Expenses() {
  let fileSaveHandler = useRef<any>(null);
  const [fileList, setFileList] = useState<any>([]);
  const [progressObj, setProgressObj] = useState<any>({});
  const [progressVisible, setProgressVisible] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(false);
  // 校验状态
  const [validateStatus, setValidateStatus] = useState<any>({});
  // 表单数据
  const [formData, setFormData] = useState<any>({});

  let macAddress = "";
  if (window.electron) {
    window.electron.plat.getMacAddress().then((res: any) => {
      macAddress = res;
    });
  }

  // 表单数据校验
  const validateForm = () => {
    let status = {};
    if (!formData.file) {
      status = { ...status, file: "error" };
    }
    if (!formData.password) {
      status = { ...status, password: "error" };
    }
    setValidateStatus(status);
    return Object.keys(status).length === 0;
  };

  const handleFileSingle = async (file: any, fileId: string | number) => {
    if (!file) {
      message.error("请选择文件");
      return;
    }
    if (!formData.password) {
      message.error("请输入密码");
      return;
    }
    let AESKey = formData.password;
    // 不足16位的密码补0
    if (formData.password?.length < 16) {
      AESKey = formData.password.padEnd(16, "\0");
    }
    const AESIv = genIv(AESKey);

    const decFn = (encryptedText: any) => {
      try {
        const plaintext = AESDecData(encryptedText, AESKey, AESIv);
        return plaintext;
      } catch (e) {
        message.error("解密失败，请检查文件和密码");
        setCompleted(true);
        throw e;
      }
    };
    const regex = /(.*)\.(.*)$/;
    const match = file.name.match(regex);
    let name = match[1];
    const extension = match[2];
    name = name.replace(/_encrypted$/, "");
    // 文件流处理
    const opts = {
      suggestedName: name + "_decrypted." + extension,
      types: [],
    };
    let writableStream: { close: () => any; write: (arg0: any) => any };
    let streamHandler: (stream: any, progress: number) => void;
    if (!window.electron) {
      try {
        // 请求用户授权访问文件系统
        // const handle = await (window as any).showSaveFilePicker(opts); // 弹出文件选择框，兼容性问题
        const handle = fileSaveHandler.current;
        if (!handle) {
          message.error("请选择保存目录");
          return;
        }
        setProgressVisible(true);
        // 创建一个WritableStream对象，用于写入数据到本地文件
        writableStream = await handle?.createWritable();
      } catch (e) {
        console.log("报错了", e.message);
        if (e.message.indexOf("Failed to execute 'showSaveFilePicker'") > -1) {
          message.error("请允许访问文件系统");
        }
        return;
      }
      streamHandler = async (stream: any, progress: number) => {
        // console.log("stream", stream, progress);
        setProgressObj((prev: any) => {
          return {
            ...prev,
            [fileId]: {
              progress: Number((progress * 100).toFixed(2)),
              fileName: file.name,
            },
          };
        });
        if (!stream && progress === 1) {
          // 关闭写入流
          await writableStream.close();
          fileSaveHandler.current = null;
          return;
        }
        // 写入本地文件
        await writableStream.write(stream);
      };
    }

    if (window.electron) {
      const fs = window.electron.fs;
      setProgressVisible(true);
      streamHandler = async (stream: any, progress: number) => {
        setProgressObj((prev: any) => {
          return {
            ...prev,
            [fileId]: {
              progress: Number((progress * 100).toFixed(2)),
              fileName: file.name,
            },
          };
        });
        let isEnd = false;
        if (!stream && progress === 1) {
          isEnd = true;
        }
        fs.writeStream({
          path: formData.saveDir,
          options: { flags: "a" },
          data: stream,
          end: isEnd,
        });
      };
    }

    let ret = await aesDecryptFileByChunk(file, decFn, streamHandler).catch(
      (e) => {
        console.log("解密失败", e);
        setCompleted(true);
        message.error("解密失败，请检查文件和密码");
      }
    );
    // console.log("解密完成", ret);
    return;
  };

  const handleFileChanged = async (files: any[]) => {
    const obj = {};
    for (let i = 0; i < files.length; i++) {
      obj[i] = {
        progress: 0,
        fileName: files[i].name,
      };
    }
    setProgressObj(obj);
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      await handleFileSingle(file, i);
    }
    reportImportLog(files);
  };

  // 上报导出日志
  const reportImportLog = async (fileList: any) => {
    const res = await SERVICE_API.opAudit.reportExportLog({
      opType: "文件解密导入",
      macAddress: macAddress,
      fileList: fileList.map((item: any) => {
        return {
          fileName: item.name,
          filePath: item.path || "",
          fileMd5: item.md5 || "",
        };
      }),
    });
    if (res?.code === 0) {
      console.log("上报成功");
    }
  };

  // 执行加密导出
  const handleExport = () => {
    // if (!validateForm()) {
    //   return;
    // }
    if (!fileList || fileList.length === 0) {
      message.error("请选择文件");
      return;
    }
    if (!formData.password) {
      message.error("请输入密码");
      return;
    }
    handleFileChanged(fileList);
  };

  // 选择目录
  const chooseDir = async () => {
    const file = fileList[0];
    if (!file) {
      message.error("请先选择文件");
      return;
    }
    const regex = /(.*)\.(.*)$/;
    const match = file.name.match(regex);
    let name = match[1];
    const extension = match[2];
    name = name.replace(/_encrypted$/, "");
    if (window.electron) {
      const res = await window.electron.dialog.saveFile({
        defaultPath: name + "_decrypted." + extension,
        // properties: ["openDirectory"],
      });
      setFormData({ ...formData, saveDir: res });
      return;
    }
    // 文件流处理
    const opts = {
      suggestedName: name + "_decrypted." + extension,
      types: [],
    };
    try {
      // 请求用户授权访问文件系统
      const handle = await (window as any).showSaveFilePicker(opts); // 弹出文件选择框，兼容性问题
      fileSaveHandler.current = handle;
      setFormData({ ...formData, saveDir: opts.suggestedName });
      return handle;
    } catch (e) {
      console.log("报错了", e.message);
      if (e.message.indexOf("Failed to execute 'showSaveFilePicker'") > -1) {
        message.error("请允许访问文件系统");
      }
      return null;
    }
  };

  // onFormChange
  const onFormChange = (key: string, value: any) => {
    setFormData({ ...formData, [key]: value });
  };

  useEffect(() => {
    // 所有文件导出完成
    const progressArr = Object.keys(progressObj).map((key) => {
      return progressObj[key];
    });
    const completedT = progressArr.every((item) => item.progress === 100);
    setCompleted(completedT);
  }, [progressObj]);

  const props: UploadProps = {
    name: "file",
    multiple: false,
    fileList: fileList,
    action: "",
    listType: "picture",
    beforeUpload(file, fileList) {
      // console.log("beforeUpload", file, fileList);
      // setFileList(fileList);
      setFileList([file]);
      return false;
    },
    onChange() {
      setFormData({ ...formData, saveDir: undefined });
      fileSaveHandler.current = null;
    },
    onDrop(e) {
      // console.log("Dropped files", e.dataTransfer.files);
    },
    onRemove(file) {
      setFileList([]);
    },
  };

  return (
    <>
      <main style={{ padding: 30, paddingTop: 6 }}>
        <div className={styles.form_item}>
          <div className={styles.label}>选择文件</div>
          {/* <Input style={{ width: "100%" }} placeholder="请选择需要加密的文件" /> */}
          <Dragger {...props}>
            {fileList.length === 0 && (
              <div style={{ padding: "16px 0" }}>
                <p className="ant-upload-drag-icon" style={{ marginBottom: 6 }}>
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text" style={{ fontSize: 14 }}>
                  点击或拖动文件到此区域完成选择
                </p>
                <p className="ant-upload-hint" style={{ fontSize: 12 }}>
                  请选择通过本工具安全导出的文件进行导入
                </p>
              </div>
            )}
          </Dragger>
        </div>
        <div className={styles.form_item}>
          <div className={styles.label}>保存目录</div>
          <Input
            style={{ width: "100%" }}
            value={formData.saveDir}
            addonAfter={<a onClick={chooseDir}>选择</a>}
            disabled
          />
        </div>
        <div className={styles.form_item}>
          <div className={styles.label}>解密密码</div>
          <Input.Password
            style={{ width: "100%" }}
            maxLength={16}
            minLength={8}
            placeholder="请输入密码"
            value={formData.password}
            onChange={(e) => {
              onFormChange("password", e.target.value);
            }}
          />
          <div className={styles.tips}>请输入文件加密时设置的密码</div>
        </div>
        <Button
          type="primary"
          style={{ width: "100%", marginTop: 20 }}
          size="large"
          onClick={() => handleExport()}
        >
          开始导入
        </Button>
      </main>
      <Modal
        title="导入中..."
        open={progressVisible}
        centered
        closable={false}
        maskClosable={false}
        footer={
          <Row justify="center" align="middle">
            <Button
              style={{
                // background: "#00b22f",
                // borderColor: "#00b22f",
                width: "calc(100%)",
              }}
              disabled={!completed}
              type="primary"
              size="large"
              onClick={() => {
                setProgressObj({});
                setProgressVisible(false);
                setFileList([]);
                setFormData({});
              }}
            >
              完成
            </Button>
          </Row>
        }
        cancelText="取消"
        okText="完成"
        okButtonProps={{ disabled: !completed }}
        onCancel={() => {
          Modal.confirm({
            title: "确认取消导入？",
            content: "取消后需要重新授权",
            centered: true,
            onOk: () => {
              setProgressVisible(false);
              setProgressObj({});
            },
            okText: "确认",
            cancelText: "取消",
          });
        }}
      >
        <div
          style={{
            marginBottom: 20,
            color: "#f50",
            fontSize: 12,
            background: "#fff1f0",
            border: "1px solid #ffa39e",
            borderRadius: 4,
            padding: "5px 15px",
          }}
        >
          提示：文件和密码不匹配时，解密依旧会执行，若解密出来的文件打开错误，请仔细检查加密文件和解密密码。
        </div>
        <div
          style={{
            marginBottom: 20,
            fontSize: 14,
            background: "#fafafa",
            padding: "10px 15px",
            borderRadius: 4,
          }}
        >
          <div style={{ marginBottom: 12, fontWeight: 600, color: "#666" }}>
            执行进度
          </div>
          {Object.keys(progressObj).map((key) => {
            const item = progressObj[key];
            return (
              <div
                key={key}
                style={{
                  marginBottom: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  // borderBottom: "1px solid #f0f0f0",
                }}
              >
                <div style={{ flex: 1 }}>
                  <a
                    style={{ marginRight: 14, fontSize: 14, cursor: "default" }}
                  >
                    {item.fileName}
                  </a>
                  <div style={{ width: "calc" }}>
                    <Progress percent={item.progress} showInfo={false} />
                  </div>
                </div>
                {/* <div style={{ flex: "0 0 60px", padding: 10 }}>
                <div
                  style={{
                    width: 40,
                    height: 40,
                    background: "#f5f5f5",
                    borderRadius: 4,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <a>保存</a>
                </div>
              </div> */}
              </div>
            );
          })}
        </div>
      </Modal>
    </>
  );
}
