const dateJS = {
  // 判断是否闰年
  checkLeapYear: function (year) {
    return !(year % 4) && year % 100;
  },

  // 获取月份的天数
  getMonthDays: function (month, isLeapYear) {
    return [1, 3, 5, 7, 8, 10, 12].indexOf(month) > -1
      ? 31
      : month == 2
      ? isLeapYear
        ? 29
        : 28
      : 30;
  },

  // 获取今天
  today: function () {
    const date = new Date();
    let array = [];
    array.push(date.getFullYear());
    array.push(("0" + (date.getMonth() + 1)).slice(-2));
    array.push(("0" + date.getDate()).slice(-2));
    return array.join("-");
  },

  // 获取今天后N天中文
  afterTodayCn: function (n) {
    const date = new Date();
    let array = [];
    var afterTodayTime = date.getTime() + 1000 * 60 * 60 * 24 * n;
    var afterToday = new Date(afterTodayTime);
    let arrayTwo = [];
    const year = afterToday.getFullYear();
    const month = ("0" + (afterToday.getMonth() + 1)).slice(-2);
    const day = ("0" + afterToday.getDate()).slice(-2);
    const week = afterToday.getDay();
    const weekCn = ["日", "一", "二", "三", "四", "五", "六"];
    return {
      dateStr: year + "-" + month + "-" + day,
      dateStrCn: year + "年" + month + "月" + day + "日",
      dateStrShortCn: month + "月" + day + "日",
      week: "周" + weekCn[week],
      weekLong: "星期" + weekCn[week],
    };
  },
  // 获取今天后N天
  afterToday: function (n) {
    const date = new Date();
    let array = [];
    var afterTodayTime = date.getTime() + 1000 * 60 * 60 * 24 * n;
    var afterToday = new Date(afterTodayTime);
    let arrayTwo = [];
    arrayTwo.push(afterToday.getFullYear());
    arrayTwo.push(("0" + (afterToday.getMonth() + 1)).slice(-2));
    arrayTwo.push(("0" + afterToday.getDate()).slice(-2));
    return arrayTwo.join("-");
  },
  // 获取某天后N天
  afterDay: function (dateStr, n) {
    const date = new Date(dateStr);
    let array = [];
    var afterTodayTime = date.getTime() + 1000 * 60 * 60 * 24 * n;
    var afterToday = new Date(afterTodayTime);
    let arrayTwo = [];
    arrayTwo.push(afterToday.getFullYear());
    arrayTwo.push(("0" + (afterToday.getMonth() + 1)).slice(-2));
    arrayTwo.push(("0" + afterToday.getDate()).slice(-2));
    return arrayTwo.join("-");
  },
  // 日期格式转换
  dateStrTrans: function (dateStr) {
    const date = new Date(dateStr);
    let array = [];
    var year = date.getFullYear();
    var month = ("0" + (date.getMonth() + 1)).slice(-2);
    var day = ("0" + date.getDate()).slice(-2);
    const week = date.getDay();
    const weekCnList = ["日", "一", "二", "三", "四", "五", "六"];
    const weekCn = weekCnList[week];
    return {
      cnMonthDay: month + "月" + day + "日",
      cnYearMonthDay: year + "年" + month + "月" + day + "日",
      year,
      month,
      day,
      week,
      weekCn,
    };
  },

  // 获取本周起始日期
  weekStartAndEndDay: function (day) {
    const date = day ? new Date(day) : new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;

    const isLeapYear = this.checkLeapYear(date.getFullYear());
    const days = this.getMonthDays(month, isLeapYear);
    const today = date.getDate();
    // 当前处于一周的那一天（星期天为7）
    const dayInWeek = date.getDay() == 0 ? 7 : date.getDay();

    // 开始日期
    let weekStartDay: any[] = [year, month];
    let startDay = today - Math.abs(1 - dayInWeek);
    if (startDay < 1) {
      let monthDiff = month - 1;
      let startYear = monthDiff < 1 ? year - 1 : year;
      let startMonth = monthDiff < 1 ? 12 : monthDiff;
      weekStartDay[0] = startYear;
      weekStartDay[1] = startMonth;
      weekStartDay.push(
        this.getMonthDays(startMonth, this.checkLeapYear(startYear)) + startDay
      );
    } else {
      weekStartDay.push(startDay);
    }

    // 结束日期
    let weekEndDay: any[] = [year, month, today + Math.abs(7 - dayInWeek)];

    if (weekEndDay[2] > days) {
      // 超出本月
      let monthDiff = month + 1;
      weekEndDay[0] = monthDiff > 12 ? year + 1 : year;
      weekEndDay[1] = monthDiff > 12 ? 1 : monthDiff;
      weekEndDay[2] = weekEndDay[2] - days;
    }
    weekStartDay[1] = ("0" + weekStartDay[1]).slice(-2);
    weekStartDay[2] = ("0" + weekStartDay[2]).slice(-2);
    weekEndDay[1] = ("0" + weekEndDay[1]).slice(-2);
    weekEndDay[2] = ("0" + weekEndDay[2]).slice(-2);

    return [weekStartDay.join("-"), weekEndDay.join("-")];
  },

  // 获取本周起始日期
  monthStartAndEndDay: function (day) {
    const date = day ? new Date(day) : new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const isLeapYear = this.checkLeapYear(date.getFullYear());
    const days = this.getMonthDays(month, isLeapYear);
    const today = date.getDate();
    // 开始日期
    let weekStartDay: any[] = [year, month, 1];

    // 结束日期
    let weekEndDay: any[] = [year, month, days];
    weekStartDay[1] = ("0" + weekStartDay[1]).slice(-2);
    weekStartDay[2] = ("0" + weekStartDay[2]).slice(-2);
    weekEndDay[1] = ("0" + weekEndDay[1]).slice(-2);
    weekEndDay[2] = ("0" + weekEndDay[2]).slice(-2);
    return [weekStartDay.join("-"), weekEndDay.join("-")];
  },

  timeFormat: function (seconds) {
    seconds = parseInt(seconds);
    let hours, mins, secs;
    let result = "";
    secs = "0" + (seconds % 60);
    mins = "0" + (seconds % 3600) / 60;
    hours = "0" + seconds / 3600;

    result = `${hours.slice(-2)}:${mins.slice(-2)}:${secs.slice(-2)}`;
    return result;
  },

  getWeek: function (dateStr) {
    const date = new Date(dateStr);
    const week = date.getDay();
    const weekCn = ["日", "一", "二", "三", "四", "五", "六"];

    return {
      number: week,
      cn: weekCn[week],
    };
  },
};

export default dateJS;
