import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Table,
} from "antd";
import { useEffect, useRef, useState } from "react";
import zhCN from "antd/lib/locale/zh_CN";
import { ColumnsType } from "antd/lib/table";
import ReactJson from "react-json-view";
import SERVICE_API from "@/api";

const ZhCNLocale: typeof zhCN = zhCN;
ZhCNLocale.DatePicker!.lang = {
  ...zhCN.DatePicker!.lang,
  monthFormat: "M月",
  shortWeekDays: ["日", "一", "二", "三", "四", "五", "六"],
};

export default function Logs() {
  const formRef = useRef<any>();
  const [visible, setVisible] = useState(false);
  const [curRecord, setCurRecord] = useState<any>({});
  const [list, setList] = useState([]);
  const [pageInfo, setPageInfo] = useState({
    pageSize: 10,
    current: 1,
    total: 10,
  });
  //   设置分页
  const [pageParam, setPageParam] = useState({
    pageSize: 10,
    current: 1,
  });

  const [moduleEnum, setModuleEnum] = useState({
    file: {
      text: "文件",
      status: "file",
    },
    user: {
      text: "用户",
      status: "user",
    },
    org: {
      text: "机构",
      status: "org",
    },
  });

  const [typeEnum, setTypeEnum] = useState({
    query: {
      text: "查询",
      status: "query",
    },
    add: {
      text: "新增",
      status: "add",
    },
    add_or_update: {
      text: "新增或更新",
      status: "add_or_update",
    },
    update: {
      text: "更新",
      status: "update",
    },
    delete: {
      text: "删除",
      status: "delete",
    },
    login: {
      text: "登录",
      status: "login",
    },
  });

  // 获取列表数据
  const getLogList = async (params: any) => {
    const form = formRef.current;
    const search = form?.getFieldsValue() || {};
    const finalSearch = {
      ...search,
      ...params,
      startTime: search.time?.[0]?.format("YYYY-MM-DD 00:00:00") || "",
      endTime: search.time?.[1]?.format("YYYY-MM-DD 23:59:59") || "",
      time: undefined,
    };
    console.log("search", finalSearch);
    const res = await SERVICE_API.logs.getOpLogList(finalSearch);
    setPageParam({ ...params });
    console.log("res", res);
    if (res?.code === 0) {
      setList(res?.data?.list);
      setPageInfo({
        pageSize: params.pageSize,
        current: params.current,
        total: res?.data?.total,
      } as any);
    }
    return {
      success: res?.code === 0, //   刷新，如果res.code===0 就不刷新了
      data: res?.data?.list, //    表格的数据
      total: res?.data?.total,
    };
  };

  useEffect(() => {
    getLogList(pageParam);
  }, []);

  const columns: ColumnsType<any> = [
    {
      title: "编号",
      dataIndex: "recordId",
      key: "recordId",
      align: "center",
      width: 80,
      render: (text) => <a style={{ fontSize: 12 }}>{text}</a>,
    },
    {
      title: "操作",
      dataIndex: "operation",
      key: "operation",
      align: "center",
      render: (text) => <span style={{ fontSize: 12 }}>{text}</span>,
    },
    {
      title: "操作内容",
      dataIndex: "commit",
      key: "commit",
      align: "center",
      width: 80,
      render: (text, record) => (
        <a
          style={{ color: "#1890ff", fontSize: 12, userSelect: "none" }}
          title={text}
          onClick={() => {
            const recordT = JSON.parse(JSON.stringify(record));
            try {
              const json = JSON.parse(recordT.commit);
              recordT.commit = json;
            } catch (e) {}
            setCurRecord(recordT);
            setVisible(true);
          }}
        >
          查看
        </a>
      ),
    },
    {
      title: "结果",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 50,
      render: (text) => (
        <span style={{ fontSize: 10, color: text == 0 ? "green" : "#888" }}>
          {text == 0 ? "成功" : "失败"}
        </span>
      ),
    },
    {
      title: "IP地址",
      dataIndex: "ip",
      key: "ip",
      align: "center",
      render: (text) => <span style={{ fontSize: 10 }}>{text}</span>,
    },
    {
      title: "时间",
      key: "opTime",
      dataIndex: "opTime",
      width: 140,
      align: "center",
      render: (text) => <span style={{ fontSize: 10 }}>{text}</span>,
    },
  ];

  return (
    <div>
      <Card
        style={{ marginBottom: 18 }}
        bodyStyle={{ padding: "15px 15px 0 15px" }}
      >
        <Form size="small" ref={formRef}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="操作" name="operation">
                <Input type="text" allowClear />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="IP地址" name="ip">
                <Input type="text" allowClear />
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item label="操作内容" name="commit">
                <Input type="text" allowClear />
              </Form.Item>
            </Col> */}
            <Col span={16}>
              <Form.Item label="时间" name="time">
                <DatePicker.RangePicker
                  locale={ZhCNLocale.DatePicker}
                  showTime={false}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item>
                <Space>
                  <Button
                    type="primary"
                    style={{ width: 160 }}
                    onClick={() => {
                      getLogList(pageParam);
                    }}
                  >
                    查询
                  </Button>
                  {/* <Button type="primary" ghost>
                    导出
                  </Button> */}
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
      <Table
        columns={columns}
        dataSource={list}
        size="small"
        scroll={{ y: 270 }}
        rowKey={(record) => record.recordId}
        pagination={{
          total: pageInfo.total,
          pageSize: pageInfo.pageSize,
          current: pageInfo.current,
        }}
        onChange={(pagination) => {
          getLogList({
            pageSize: pagination.pageSize,
            current: pagination.current,
          });
        }}
      />
      <Modal
        title="查看操作内容"
        open={visible}
        footer={null}
        centered
        onCancel={() => {
          setVisible(false);
        }}
        destroyOnClose
        style={{ maxHeight: "90vh" }}
        bodyStyle={{
          height: "480px",
          overflowY: "auto",
        }}
      >
        {/* <h3>请求地址：</h3>
        <p style={{ color: "#002b36" }}>{curRecord.path}</p> */}
        {/* <h3>操作参数：</h3> */}
        {typeof curRecord.commit != "string" ? (
          <ReactJson
            src={curRecord.commit}
            displayObjectSize={false}
            displayDataTypes={false}
            iconStyle="square"
            name="Request"
          />
        ) : (
          <p>{curRecord.commit}</p>
        )}
      </Modal>
    </div>
  );
}
