import { Button, Card, Modal, Row, message } from "antd";
import { useEffect, useState } from "react";
import style from "./index.module.css";
import hex_md5 from "@/utils/md5";
import SERVICE_API from "@/api";
import { ModalForm, ProFormText } from "@ant-design/pro-form";
import history from "@/utils/history";
import Cookies from "js-cookie";
// import { await } from '@umijs/deps/compiled/signale';

export default function Safety() {
  const [Visibel, setVisibel] = useState(false);
  const [phoneNo, setPhoneNo] = useState("");
  const [detail, setDetail] = useState<any>({}); //详情数据

  // 设置label标签和input标签的宽度
  const [formLayout] = useState<any>("horizontal");
  const formItemLayout =
    formLayout === "horizontal"
      ? { labelCol: { span: 5 }, wrapperCol: { span: 14 } }
      : null;
  //  获取详情
  const getUserDetail = async () => {
    const res = await SERVICE_API.login.getUserInfo({});
    if (res.code === 0) {
      setDetail(res.data?.userInfo);
      return {};
    }
  };
  useEffect(() => {
    getUserDetail();
  }, []);

  // 修改密码
  const onFinish = async (value: any) => {
    if (value.passwordVerify !== value.password) {
      message.error("密码不一致，请重新输入");
      return;
    }
    const res = await SERVICE_API.login.changePassword({
      oldPassword: hex_md5(value.oldPassword),
      password: hex_md5(value.password),
    });
    if (res.code === 0) {
      message.success("修改成功,请重新登录");
      setVisibel(false);
      history.push("/user/login");
    }
  };

  // 退出登录
  const logout = async () => {
    const res = await SERVICE_API.login.logout({});
    if (res.code === 0) {
      message.success("退出成功");
      localStorage.removeItem("userInfo");
      sessionStorage.removeItem("userInfo");
      localStorage.removeItem("adminlogin@token");
      sessionStorage.removeItem("adminlogin@token");
      localStorage.removeItem("orgInfo");
      sessionStorage.removeItem("orgInfo");
      // 删除cookie
      Cookies.remove("QC-Token");
      history.push("/");
    }
  };

  return (
    <div>
      <Card title="" bordered={false} bodyStyle={{ padding: 0 }}>
        <div className={style.ChangePw}>
          <div>
            <i>姓名</i>
          </div>
          <a style={{ cursor: "default" }}>{detail.realName}</a>
        </div>
        {/* <div className={style.ChangePw}>
          <div>
            <i>登录密码</i>
            <span>当前账号状态：正常</span>
          </div>
          <a
            onClick={() => {
              setVisibel(true);
            }}
          >
            修改
          </a>
        </div> */}
        <div className={style.ChangePw}>
          <div>
            <i>操作密码</i>
            <span>已设置</span>
          </div>
          <a
            onClick={() => {
              setVisibel(true);
            }}
          >
            修改
          </a>
        </div>
      </Card>
      <Row justify="center" align="middle" style={{ marginTop: 40 }}>
        <Button danger style={{ width: "100%" }} onClick={() => logout()}>
          退出登录
        </Button>
      </Row>
      <ModalForm
        title="修改密码"
        layout="horizontal"
        labelCol={{ span: 6 }}
        width={360} // 弹框的宽度
        open={Visibel}
        onFinish={onFinish}
        onOpenChange={setVisibel}
        modalProps={{
          centered: true,
        }}
      >
        <ProFormText.Password
          name="oldPassword"
          label="旧密码"
          placeholder="请输入旧密码"
          rules={[{ required: true, message: "请输入旧密码" }]}
        />
        <ProFormText.Password
          name="password"
          label="新密码"
          placeholder="请输入新密码"
          rules={[{ required: true, message: "请输入新密码" }]}
        />
        <ProFormText.Password
          name="passwordVerify"
          label="确认密码"
          placeholder="请输入新密码"
          rules={[{ required: true, message: "请输入新密码" }]}
        />
      </ModalForm>
    </div>
  );
}
