import {
  Button,
  Input,
  Modal,
  Progress,
  Row,
  Space,
  Upload,
  UploadProps,
  message,
} from "antd";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import projectConfig from "@/config/projectConfig";
import styles from "./index.module.css";
import CryptoJS from "crypto-js";
import {
  AESDecData,
  AESEncData,
  ArrayBufferToWordArray,
  WordArrayToArrayBuffer,
  aesDecryptFileByChunk,
  aesEncryptFileByChunk,
  genIv,
} from "@/utils/CryptoUtil";
import SERVICE_API from "@/api";

const { Dragger } = Upload;

export default function Expenses() {
  // 校验状态
  const [validateStatus, setValidateStatus] = useState<any>({});
  const [urlInput, setUrlInput] = useState<string>("");
  const [initUrl, setInitUrl] = useState<string>("");

  const setStore = () => {
    if (window.electron) {
      const key = "baseUrl";
      const value = urlInput;
      window.electron.store.set(key, value);
      const get = window.electron.store.get(key);
      if (get === value) {
        message.success("保存成功");
      }
    }
  };

  const clearStore = () => {
    window.electron?.store.clear();
    localStorage.clear();
    sessionStorage.clear();
    message.success("清理成功");
  };

  const reset = () => {
    window.electron?.store.clear();
    // window.location.reload();
    initSetting();
  };

  const initSetting = () => {
    let value;
    if (window.electron) {
      value = window.electron.store.get("baseUrl");
    }
    if (!value) {
      value = projectConfig.baseUrl;
    }
    setInitUrl(value);
    setUrlInput(value);
  };

  useEffect(() => {
    initSetting();
  }, []);

  // 校验
  const validateUrl = (url: string) => {
    // 以http://或https://开头
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      setValidateStatus({
        ...validateStatus,
        url: {
          status: "error",
          message: "地址必须以http://或https://开头",
        },
      });
      return false;
    }
    // 不能以/结尾
    if (url.endsWith("/")) {
      setValidateStatus({
        ...validateStatus,
        url: {
          status: "error",
          message: "地址不能以/结尾",
        },
      });
      return false;
    }
    return true;
  };

  return (
    <>
      <main style={{ padding: 30, paddingTop: 6 }}>
        <div className={styles.form_item}>
          <div className={styles.label}>网络请求地址设置</div>
          <Input
            value={urlInput}
            style={{ width: "100%" }}
            placeholder="可在此处配置服务器请求地址，地址不能以/结尾"
            onChange={(e) => {
              setUrlInput(e.target.value);
              if (validateUrl(e.target.value)) {
                setValidateStatus({
                  ...validateStatus,
                  url: undefined,
                });
              }
            }}
            onBlur={(e) => {
              if (validateUrl(e.target.value)) {
                setValidateStatus({
                  ...validateStatus,
                  url: undefined,
                });
                setStore();
              }
            }}
          />
          {validateStatus.url?.status === "error" && (
            <div className={styles.tips} style={{ color: "red" }}>
              {validateStatus.url?.message}
            </div>
          )}
          <div className={styles.tips}>
            可在此处配置服务器请求地址，地址必须以http://或https://开头，不能以/结尾。
          </div>
        </div>
        <div className={styles.form_item}>
          <div className={styles.label}>清理重置</div>
          <Space>
            <Button
              onClick={() => {
                reset();
              }}
            >
              重置设置
            </Button>
            <Button
              onClick={() => {
                clearStore();
              }}
            >
              清理缓存
            </Button>
          </Space>
        </div>
      </main>
    </>
  );
}
