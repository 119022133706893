export type ProjectConfigType = {
  client: string;
  title?: string;
  version?: string;
  baseUrl?: string;
  uploadBaseUrl?: string;
  baseUrl_DEV?: string;
  uploadBaseUrl_DEV?: string;
  apiPrefix?: string;
  publicPath?: string;
  uiConf: {
    primaryColor?: string;
    primaryBgColor?: string;
  };
};
const projectConfig: ProjectConfigType = {
  client: "101",
  title: "数据受控工具",
  version: "1.0.0",
  baseUrl: "https://hjj-safe-test.quchutech.com", // 请求url
  uploadBaseUrl: "", // 上传url
  baseUrl_DEV: "http://localhost:31001", // 请求url
  uploadBaseUrl_DEV: "", // 上传url
  apiPrefix: "/admin-api",
  publicPath: "/",
  uiConf: {
    primaryColor: "#00a26f",
    primaryBgColor: "rgba(0,162,111,.35)",
    // primaryColor: '#00a29a',
    // primaryBgColor: 'rgba(0,162,154,0.35)',
  },
};

export default projectConfig;
