import CryptoJS from "crypto-js";
import SparkMD5 from "spark-md5";

/**
 *
 * @param {*} file
 * @returns WordArray
 */
// export const aesEncryptFileByChunk = (file, encryptFunc) => {
//   return new Promise((resolve, reject) => {
//     try {
//       const chunkSize = 1024 * 1024 * 20; // 每次处理的数据块大小，这里设定为20MB
//       let offset = 0;
//       let resultWordArray = [];
//       let encryptedChunks = new Uint8Array(0);
//       const reader = new FileReader();
//       reader.onload = (event) => {
//         const arrayBuffer = event.target.result;
//         const wordArray = ArrayBufferToWordArray(arrayBuffer);
//         // 加密
//         const encryptedArrayBuffer = encryptFunc(wordArray);
//         // 获取加密后的数据块的实际长度
//         const chunkSizeBytes = encryptedArrayBuffer.byteLength;
//         // 假设 chunkSizeBytes 是一个int数字，需要存储为Uint8Array
//         var a = chunkSizeBytes;
//         var buf = new ArrayBuffer(4);
//         var view = new Int32Array(buf); // 默认LITTLE_ENDIAN排序
//         view[0] = a;
//         var bufView = new Uint8Array(buf);
//         // 将存储长度信息的 ArrayBuffer 转换为 Uint8Array
//         const sizeArray = new Uint8Array(view.buffer);
//         // 合并加密后的数据Chunk和长度信息
//         const newEncryptedChunks = new Uint8Array(
//           4 + encryptedArrayBuffer.length + encryptedChunks.length,
//         );
//         newEncryptedChunks.set(encryptedChunks, 0); // 原信息放在最前面
//         newEncryptedChunks.set(sizeArray, encryptedChunks.length); // 原信息放在最前面
//         newEncryptedChunks.set(new Uint8Array(encryptedArrayBuffer), encryptedChunks.length + 4);

//         // 释放之前的encryptedChunks内存
//         encryptedChunks = null;
//         encryptedChunks = newEncryptedChunks;
//         // resultWordArray = resultWordArray.concat(wordArray);
//         // 进度
//         const progress = ((offset / file.size) * 100).toFixed(2) + '%';
//         console.log('已完成：', progress, '(', offset / 1024 / 1024 + 'MB)');
//         offset += arrayBuffer.byteLength;
//         if (offset < file.size) {
//           readNextChunk();
//         } else {
//           // 处理完成
//           console.log('处理完成：100%', file.size / 1024 / 1024 + 'MB');
//           resolve({ wordBuffer: resultWordArray, encryptedArrayBuffer: encryptedChunks });
//         }
//       };
//       const readNextChunk = () => {
//         const blob = file.slice(offset, offset + chunkSize);
//         reader.readAsArrayBuffer(blob);
//       };
//       readNextChunk();
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// aes文件分片加密
export const aesEncryptFileByChunk = (file, encryptFunc, streamHandler) => {
  return new Promise(async (resolve, reject) => {
    const chunkSize = 1024 * 1024 * 20; // 每次处理的数据块大小，这里设定为20MB
    let offset = 0;
    let encryptedChunks = new Uint8Array(0);
    let errorMsg = "";

    // 整体输出只能处理2GB的文件，大于2GB的文件请自行使用streamHandle函数处理
    if (file.size > 2 * 1024 * 1024 * 1024) {
      errorMsg =
        "文件过大，最大只能处理2GB的文件，超过2GB的文件请自行使用streamHandle函数处理！";
      console.error(errorMsg);
    }

    const reader = new FileReader();
    reader.onload = async (event) => {
      try {
        const arrayBuffer = event.target.result;
        const wordArray = ArrayBufferToWordArray(arrayBuffer);
        // 加密
        const encryptedArrayBuffer = encryptFunc(wordArray);
        // 获取加密后的数据块的实际长度
        const chunkSizeBytes = encryptedArrayBuffer.byteLength;
        // console.log("chunkSizeBytes:", chunkSizeBytes);
        // 假设 chunkSizeBytes 是一个int数字，需要存储为Uint8Array
        var a = chunkSizeBytes;
        var buf = new ArrayBuffer(4);
        var view = new Int32Array(buf); // 默认LITTLE_ENDIAN排序
        view[0] = a;
        var bufView = new Uint8Array(buf);
        // 将存储长度信息的 ArrayBuffer 转换为 Uint8Array
        const sizeArray = new Uint8Array(view.buffer);
        // 还原sizeArray到int
        const intRestore = new Int32Array(sizeArray.buffer)[0];
        // console.log("还原sizeArray到int:", intRestore);
        // 合并加密后的数据Chunk和长度信息
        const newEncryptedChunks = new Uint8Array(
          4 + encryptedArrayBuffer.length
        );
        newEncryptedChunks.set(sizeArray, 0); // 原信息放在最前面
        newEncryptedChunks.set(new Uint8Array(encryptedArrayBuffer), 4);

        // 流式回调
        streamHandler(newEncryptedChunks, offset / file.size);
        // 合并已经加密的数据和本次加密的数据-start
        // 这里有限制，最大只能处理2GB的文件，因为Uint8Array的最大长度是2^32-1
        if (file.size < 2 * 1024 * 1024 * 1024) {
          const newEncryptedChunks2 = new Uint8Array(
            encryptedChunks.length + newEncryptedChunks.length
          );
          newEncryptedChunks2.set(encryptedChunks, 0);
          newEncryptedChunks2.set(newEncryptedChunks, encryptedChunks.length);
          // 释放之前的encryptedChunks内存
          encryptedChunks = null;
          encryptedChunks = newEncryptedChunks2;
          // resultWordArray = resultWordArray.concat(wordArray);
        }
        // 合并已经加密的数据和本次加密的数据-end

        // 进度
        const progress = ((offset / file.size) * 100).toFixed(2) + "%";
        // console.log("已完成：", progress, "(", offset / 1024 / 1024 + "MB)");
        offset += arrayBuffer.byteLength;
        if (offset < file.size) {
          readNextChunk();
        } else {
          // 处理完成
          // console.log("处理完成：100%", file.size / 1024 / 1024 + "MB");
          streamHandler(null, 1);
          resolve({
            encryptedArrayBuffer: encryptedChunks,
            errorMsg: errorMsg,
          });
        }
      } catch (error) {
        reject(error);
      }
    };
    const readNextChunk = () => {
      const blob = file.slice(offset, offset + chunkSize);
      reader.readAsArrayBuffer(blob);
    };
    readNextChunk();
  });
};

// aes文件分片解密
export const aesDecryptFileByChunk = (file, decryptFunc, streamHandler) => {
  return new Promise((resolve, reject) => {
    let chunkSize = 4; // 每次处理的数据块长度
    let offset = 0;
    let decryptedChunks = new Uint8Array(0);
    let times = 0;
    let errorMsg = "";

    // 整体输出只能处理2GB的文件，大于2GB的文件请自行使用streamHandle函数处理
    if (file.size > 2 * 1024 * 1024 * 1024) {
      errorMsg =
        "文件过大，最大只能处理2GB的文件，超过2GB的文件请自行使用streamHandle函数处理！";
      console.error(errorMsg);
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const arrayBuffer = event.target.result;
        // console.log("times:", times, arrayBuffer);
        // chunkSize=4的块不需要解密，直接获取长度信息
        if (chunkSize === 4) {
          // 获取加密后的数据块的实际长度
          chunkSize = new Int32Array(arrayBuffer)[0];
          // console.log("chunkSize:", chunkSize);
          offset += 4;
          times += 1;
          return readNextChunk();
        } else {
          chunkSize = 4;
          times += 1;
        }

        const wordArray = ArrayBufferToWordArray(arrayBuffer);
        // 解密
        const decryptedArrayBuffer = decryptFunc(wordArray);
        // console.log("decryptedArrayBuffer:", decryptedArrayBuffer.length);
        streamHandler(decryptedArrayBuffer, offset / file.size);
        if (file.size < 2 * 1024 * 1024 * 1024) {
          // 合并解密后的数据Chunk和长度信息
          const newDecryptedChunks = new Uint8Array(
            decryptedChunks.length + decryptedArrayBuffer.length
          );
          newDecryptedChunks.set(decryptedChunks, 0);
          newDecryptedChunks.set(
            new Uint8Array(decryptedArrayBuffer),
            decryptedChunks.length
          );

          // 释放之前的decryptedChunks内存
          decryptedChunks = null;
          decryptedChunks = newDecryptedChunks;
        }
        // resultWordArray = resultWordArray.concat(wordArray);
        // 进度
        const progress = ((offset / file.size) * 100).toFixed(2) + "%";
        // console.log("已完成：", progress, "(", offset / 1024 / 1024 + "MB)");
        offset += arrayBuffer.byteLength;
        if (offset < file.size) {
          readNextChunk();
        } else {
          // 处理完成
          // console.log("处理完成：100%", file.size / 1024 / 1024 + "MB");
          streamHandler(null, 1);
          resolve({
            decryptedArrayBuffer: decryptedChunks,
            errorMsg: errorMsg,
          });
        }
      } catch (error) {
        reject(error);
      }
    };
    const readNextChunk = () => {
      const blob = file.slice(offset, offset + chunkSize);
      reader.readAsArrayBuffer(blob);
    };
    readNextChunk();
  });
};

export const ArrayBufferToWordArray = (arrayBuffer) => {
  const u8 = new Uint8Array(arrayBuffer, 0, arrayBuffer.byteLength);
  const len = u8.length;
  const words = [];
  for (let i = 0; i < len; i += 1) {
    words[i >>> 2] |= (u8[i] & 0xff) << (24 - (i % 4) * 8);
  }
  return CryptoJS.lib.WordArray.create(words, len);
};

export const WordArrayToArrayBuffer = (wordArray) => {
  const { words } = wordArray;
  const { sigBytes } = wordArray;
  const u8 = new Uint8Array(sigBytes);
  for (let i = 0; i < sigBytes; i += 1) {
    const byte = (words[i >>> 2] >>> (24 - (i % 4) * 8)) & 0xff;
    u8[i] = byte;
  }
  return u8;
};

export const AESEncData = (data, key, iv) => {
  // 这里的data是WordBuffer类型的数据
  //   const bKey = CryptoJS.enc.Hex.parse(key);
  const bKey = CryptoJS.enc.Utf8.parse(key); // 这样跟java端保持一致，java端使用key.getBytes()的方式
  //   const bIv = CryptoJS.enc.Hex.parse(iv);
  const bIv = CryptoJS.enc.Utf8.parse(iv);

  const encrypt = CryptoJS.AES.encrypt(data, bKey, {
    iv: bIv,
    mode: CryptoJS.mode.OFB,
    padding: CryptoJS.pad.Pkcs7,
  });
  // base64
  //   const base64 = encrypt.toString();
  //   console.log('base64:', base64);
  const arrayBuffer = WordArrayToArrayBuffer(encrypt.ciphertext);
  return arrayBuffer;
};

export const AESDecData = (data, key, iv) => {
  try {
    // 这里的data是WordBuffer类型的数据
    // const bKey = CryptoJS.enc.Hex.parse(key);
    const bKey = CryptoJS.enc.Utf8.parse(key);
    // const bIv = CryptoJS.enc.Hex.parse(iv);
    const bIv = CryptoJS.enc.Utf8.parse(iv);
    const decrypt = CryptoJS.AES.decrypt({ ciphertext: data }, bKey, {
      iv: bIv,
      mode: CryptoJS.mode.OFB,
      padding: CryptoJS.pad.Pkcs7,
    });
    const arrayBuffer = WordArrayToArrayBuffer(decrypt);
    return arrayBuffer;
  } catch (error) {
    throw error;
  }
};

// 根据密钥计算iv
export const genIv = (key) => {
  const ivEcsKey = "8Xqv5xWQ6O5ORzTIF3LIOjsECZ8MDag7";
  const ivEcsIv = "6KzJbQYmS3ro7rAl";
  // 加密
  const keyEnc = AESEncData(key, ivEcsKey, ivEcsIv);
  const keyEncStr = ArrayBufferToWordArray(keyEnc);
  // 使用MD5做iv
  let iv = CryptoJS.MD5(keyEncStr.toString()).toString();
  // 取前16位
  iv = iv.substring(0, 16);
  return iv;
};

// 分片获取文件的MD5
export function getFileMd5ByChunck(file) {
  return new Promise((resolve, reject) => {
    let start = new Date().getTime();
    let md5 = CryptoJS.algo.MD5.create();
    let reader = new FileReader();
    let step = 1024 * 1024 * 2;
    let total = file.size;
    let cuLoaded = 0;
    let time = 1;
    let hash = "";
    console.info("文件大小：" + file.size);
    //读取一段成功
    reader.onload = function (e) {
      // console.log("开始读取第" + time + "段");
      //处理读取的结果
      let wordArray = CryptoJS.lib.WordArray.create(reader.result);
      md5.update(wordArray);
      cuLoaded += e.loaded;
      //如果没有读完，继续
      if (cuLoaded < total) {
        readBlob(cuLoaded);
      } else {
        cuLoaded = total;
        hash = md5.finalize().toString();
        // console.log("总耗时：" + (new Date().getTime() - start) + "ms");
        alert(new Date().getTime() - start + "ms");
        resolve(hash);
      }
      time++;
    };
    //开始读取
    readBlob(0);
    //指定开始位置，分块读取文件
    function readBlob(start) {
      //指定开始位置和结束位置读取文件
      let blob = file.slice(start, start + step);
      reader.readAsArrayBuffer(blob);
    }
  });
}

//计算MD5, 此方法更快
export const computeMD5 = (file) => {
  return new Promise((resolve, reject) => {
    let chunkSize = 1024 * 1024 * 2; //2MB
    let chunks = Math.ceil(file.size / chunkSize);
    let currentChunk = 0;
    let spark = new SparkMD5.ArrayBuffer();
    let reader = new FileReader();

    let time = new Date().getTime();

    file.cmd5 = true; //文件状态为“计算md5...”

    reader.onload = (e) => {
      spark.append(e.target.result); // Append array buffer
      currentChunk++;
      if (currentChunk < chunks) {
        // console.log(
        //   `第${currentChunk}分片解析完成, 开始第${
        //     currentChunk + 1
        //   } / ${chunks}分片解析`
        // );
        loadNext();
      } else {
        let md5 = spark.end(); //得到md5
        // console.log(
        //   `MD5计算完成：${file.name} \nMD5：${md5} \n分片：${chunks} 大小:${
        //     file.size
        //   } 用时：${new Date().getTime() - time} ms`
        // );
        spark.destroy(); //释放缓存
        resolve(md5);
      }
    };

    reader.onerror = () => {
      console.warn("oops, something went wrong.");
      file.cancel();
    };

    let loadNext = () => {
      let start = currentChunk * chunkSize;
      let end = start + chunkSize >= file.size ? file.size : start + chunkSize;
      //指定开始位置和结束位置读取文件
      let blob = file.slice(start, end);
      reader.readAsArrayBuffer(blob);
    };
    loadNext();
  });
};
