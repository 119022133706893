import { request } from "../request";

// 获取数据类型
export async function getDictionaryType(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/common/dict/list", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

// 获取自定义属性名称
export async function getProvinceList(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/common/dist/prov/list", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

// 获取自定义属性列表
export async function getCustomizeList(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/common/dict/custom/type/list", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}
// 编辑自定义属性列表
export async function saveCustomize(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/common/dict/custom/type/save", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}
// 删除自定义属性列表
export async function delCustomizeList(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/common/dict/custom/type/del", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

// 获取自定义属性的值
export async function getCustomAttrList(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/common/dict/custom/code/list", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}
// 获取自定义属性的值
export async function getCustomInfoAttr(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/common/dict/custom/code/detail", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}
// 编辑自定义属性的值
export async function saveCustomAttr(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/common/dict/custom/code/save", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}
// 删除自定义属性的值
export async function delCustomAttr(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/common/dict/custom/code/del", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

// 状态
export async function getStatus(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/common/dict/status/list", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

// 编辑数据字典
export async function saveDictionary(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/common/dict/save", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}
// 删除数据字典列表
export async function delDictionary(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/common/dict/del", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

// 获取级别
export async function getSign(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/resource/video/upload/sign", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

/* -----------------------  商品资源管理 ---------------------- */

// 获取规格列表
export async function getMallDictSpecList(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/mall/dict/spec/list", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

// 获取规格详情

export async function getMallDictSpecInfo(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/mall/dict/spec/detail", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

// 编辑规格详情

export async function saveMallDictSpec(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/mall/dict/spec/save", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

// 删除规格

export async function delMallDictSpec(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/mall/dict/spec/del", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

/**
 *  规格属性管理
 *
 **/
// 获取规格属性列表
export async function getMallDictSpecAttrList(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/mall/dict/spec/attr/list", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

// 编辑规格属性详情

export async function saveMallDictSpecAttr(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/mall/dict/spec/attr/save", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

// 删除规格属性列表

export async function delMallDictSpecAttr(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/mall/dict/spec/attr/del", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

// 获取普通商品的分类

export async function getGoodsType(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/mall/dict/prod-type/list", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

/**
 * 商城套餐规格及属性管理
 */

// 获取套餐规格列表
export async function getMallSpecList(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/dest/dic/mall/spec/list", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

// 保存套餐规格
export async function saveMallSpec(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/dest/dic/mall/spec/save", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

// 删除套餐规格
export async function delMallSpec(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/dest/dic/mall/spec/del", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

// 获取套餐属性列表
export async function getMallSpecAttrList(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/dest/dic/mall/spec/attr/list", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

// 保存套餐属性
export async function saveMallSpecAttr(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/dest/dic/mall/spec/attr/save", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}

// 删除套餐属性
export async function delMallSpecAttr(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/dest/dic/mall/spec/attr/del", {
    method: "POST",
    data: body,
    ...(options || {}),
  });
}
