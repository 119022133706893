import dateJS from "@/utils/date";
import { Button, Card, Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import imgUrl from "../../assets/images/home_bg.svg";
import defaultAvatar from "../../assets/logo.png";
import bannerIcon from "../../assets/logo.png";
import articleIcon from "../../assets/logo.png";
import agencyIcon from "../../assets/logo.png";
import settingIcon from "../../assets/logo.png";
import styles from "./index.module.css";
import { DeliveredProcedureOutlined, UnlockOutlined } from "@ant-design/icons";
import projectConfig from "@/config/projectConfig";

export default function Hone() {
  const navigate = useNavigate();
  const cards = [
    {
      id: "1",
      title: "数据安全导出",
      subTitle: "对计算机中的文件进行加密、授权及导出",
      icon: (
        <div className={styles.card_icon} style={{ backgroundColor: "orange" }}>
          <DeliveredProcedureOutlined style={{ fontSize: 36, color: "#fff" }} />
        </div>
      ),
      link: "/security-export",
      auth: ["M1002", "M100201"],
    },
    {
      id: "2",
      title: "数据安全导入",
      subTitle: "对加密的文件进行解密并保存",
      icon: (
        <div
          className={styles.card_icon}
          style={{ backgroundColor: "#00b22f" }}
        >
          <UnlockOutlined style={{ fontSize: 36, color: "#fff" }} />
        </div>
      ),
      link: "/security-import",
      auth: ["M1003", "M100301"],
    },
  ];

  // 点击卡片
  const handleCardClick = (item: any) => {
    navigate(item.link);
  };

  return (
    <div id="home" style={{ padding: 30, paddingTop: 6 }}>
      <Card
        style={{
          padding: 24,
          background: "#1890ff",
          color: "#fff",
          borderRadius: 4,
        }}
        bordered={false}
      >
        <div>
          {/* <span className={styles.name}>Hi，{userInfo?.nickName}</span> */}
          <div className={styles.name}>欢迎使用{projectConfig.title}</div>
          <div className={styles.time}>
            今天是&nbsp;&nbsp;&nbsp;{dateJS.afterTodayCn(0).dateStrCn}
            &nbsp;&nbsp;
            {dateJS.afterTodayCn(0).weekLong}
          </div>
        </div>
      </Card>
      <div style={{ background: "#fff", marginTop: 36 }}>
        <Row gutter={18}>
          {cards.map((item) => (
            <Col
              span={24}
              key={item.id}
              style={{ marginBottom: 24 }}
              onClick={() => handleCardClick(item)}
            >
              <div className={styles.card}>
                {item.icon}
                <div style={{ width: 12 }} />
                <div className={styles.card_content}>
                  <div className={styles.card_title}>{item.title}</div>
                  <div style={{ height: 6 }} />
                  <div className={styles.card_subtitle}>{item.subTitle}</div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}
