import styles from "./index.module.css";
import { Card, Col, Divider, List, Row, Table, Typography } from "antd";
import LogIn from "./Login";
import logo from "../../assets/logo.png";
import bg from "../../assets/images/home_bg.svg";
import SERVICE_API from "@/api";
import { useEffect, useState } from "react";

export default function Login() {
  const [articleList, setArticleList] = useState<any>([]);

  useEffect(() => {
    // getArticleList();
  }, []);

  return (
    <div
      className={styles.LoginBox}
      // style={{ background: `url("${bg}") no-repeat` }}
      style={{ background: `#1189ff` }}
    >
      <Row className={styles.Content} align="middle" justify="center">
        <Col className={styles.CardDiv}>
          <div style={{ height: 12 }} />
          <Row align="middle" justify="space-between">
            <img alt="" src={logo} className={styles.logo} />
            <div className={styles.title}>{"数据受控工具"}</div>
            <Divider
              type="vertical"
              style={{ height: 20, backgroundColor: "#F8F8F8" }}
            />
            <div className={styles.title}>登录</div>
          </Row>
          <div style={{ height: 24 }} />
          <Card className={styles.Card}>
            <LogIn />
          </Card>
          <div style={{ height: 30 }} />
        </Col>
      </Row>
      {/* <div style={{ height: 24 }}></div> */}
      {/* <div className={styles.Copyright}>
        © 2023-2024 {"数据受控工具"} · 锐安科技 All Rights Reserved
      </div> */}
    </div>
  );
}
