import React, { useState, useLayoutEffect } from "react";
import ReactDOM from "react-dom/client";
import Layout from "./layout/index.tsx";
import Home from "./pages/Home";
import SecurityExport from "./pages/SecurityExport";
import SecurityImport from "./pages/SecurityImport";
import Logs from "./pages/Logs";
import AdminHome from "./pages/AdminHome";
import UserLogin from "./pages/UserLogin";
import Setting from "./pages/Setting";
import reportWebVitals from "./reportWebVitals";
import {
  BrowserRouter,
  Router,
  Routes,
  Route,
  HashRouter,
} from "react-router-dom";
import { ConfigProvider } from "antd";
import zhCN from "antd/lib/locale/zh_CN";
import history from "./utils/history.js";

import "./index.css";
import "antd/dist/antd.min.css";

// 自定义hash路由，实现history跳转
const MyHashRouter = ({ history, ...props }) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => {
    history.listen(setState);
  }, [history]);

  return (
    <Router
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ConfigProvider locale={zhCN}>
    <MyHashRouter history={history}>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/security-export" element={<SecurityExport />} />
          <Route path="/security-import" element={<SecurityImport />} />
          <Route path="/logs" element={<Logs />} />
          <Route path="/admin-home" element={<AdminHome />} />
          <Route path="/setting" element={<Setting />} />
          <Route path="/user/login" element={<UserLogin />} />
        </Routes>
      </Layout>
    </MyHashRouter>
  </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
