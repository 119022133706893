import { request } from "../request";

// 获取数据类型
export async function getLoginPicCaptcha(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/admin/auth/captcha/pic/get", {
    method: "POST",
    data: body,
    body,
    ...(options || {}),
  });
}

// 登录验证码
export async function getLoginSmsCaptcha(
  body: Record<string, any>,
  options?: Record<string, any>
) {
  return request("/v1/comm/sms/login-verify-code", {
    method: "POST",
    data: body,
    body,
    ...(options || {}),
  });
}
